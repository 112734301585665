import Link from 'next/link';
import React from 'react';
import _ from 'lodash';

export default function SiteMap({ data, id }) {
  const items = _.get(data, 'items', null);

  return (
    <section id={id} className="prose py-12 lg:py-30">
      <div className="2xl:gap-34 relative mx-auto flex max-w-339 flex-row flex-wrap gap-8 px-8 lg:justify-center lg:gap-20 3xl:px-0">
        {_.isEmpty(items) ||
          _.map(items, (item, index) => (
            <div
              className={`flex w-[calc(50%-16px)] flex-col gap-3 lg:w-auto lg:gap-4 ${index === 0 ? 'xl:min-w-60' : ''} ${index === 1 ? 'xl:min-w-60' : ''}`}
              key={index}>
              {_.map(item?.content?.siteMapList, (sitemapList, index1) =>
                index1 === 0 ? (
                  <Link
                    href={sitemapList?.url}
                    target={sitemapList?.target}
                    key={index1}
                    className="pb-2 text-clamp16to32 !font-medium text-black underline lg:pb-4 ltr:tracking-tight">
                    {sitemapList?.name}
                  </Link>
                ) : (
                  <Link
                    href={sitemapList?.url}
                    target={sitemapList?.target}
                    key={index1}
                    className="text-clamp14to20 !font-normal tracking-normal text-text-accent underline">
                    {sitemapList?.name}
                  </Link>
                ),
              )}
            </div>
          ))}
      </div>
    </section>
  );
}
