import _ from 'lodash';
import React from 'react';
import ResponsiveImage from './layouts/ResponsiveImage';
import Image from 'next/image';

export default function WorldClassOffices({ data, id }) {
  const title = _.get(data, 'title', null);
  const items = _.get(data, 'items', []);
  const images = _.get(data, 'images[0]', {});
  const bgPattern = _.get(data, 'bgPattern', {});
  return (
    <section id={id} className="prose flex flex-col bg-background-brand-2 lg:flex-row">
      <div className="responsive-img-wrapper:h-full lg:w-1/2">
        {_.isEmpty(images) || (
          <ResponsiveImage
            desktopImage={images?.content?.image?.url}
            mobileImage={images?.content?.mobileImage?.url}
            alt={title}
            className="!h-62 object-cover md:!h-full"
          />
        )}
      </div>
      <div className="relative flex justify-center px-8 py-12 lg:w-1/2 lg:py-28">
        <div className="4xl:gap-y-22 4xl:max-w-207 relative z-10 flex flex-col justify-center gap-y-10 lg:max-w-155 lg:gap-y-16">
          {title && <h2 className="4xl:text-[62px] text-center">{title}</h2>}
          {_.isEmpty(items) || (
            <div className="4xl:gap-y-16 4xl:gap-x-16 flex flex-row flex-wrap gap-x-4 gap-y-8 lg:gap-x-8 2xl:gap-x-12 2xl:gap-y-12">
              {_.map(items, (item, index) => {
                const iconTitle = _.get(item, 'content.iconTitle', null);
                const icon = _.get(item, 'content.icon', {});
                return (
                  <div
                    key={index}
                    className="4xl:gap-y-10 flex w-[calc(50%-8px)] flex-col items-center gap-y-4 md:gap-y-8 lg:w-[calc(50%-16px)] 2xl:w-[calc(50%-24px)] 3xl:w-[calc(50%-32px)]">
                    {_.isEmpty(icon) || (
                      <Image
                        src={icon.url}
                        alt={iconTitle}
                        fill
                        className="4xl:!h-14 4xl:!w-14 !relative !h-10 !w-10"
                      />
                    )}
                    {iconTitle && (
                      <h5 className="text-center !tracking-normal !text-text-secondary rtl:!leading-medium">
                        {iconTitle}
                      </h5>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {_.isEmpty(bgPattern) || (
          <div className="!absolute inset-0 !bottom-0 h-full w-full">
            <Image
              src={bgPattern.url}
              alt={title}
              fill
              className="max-h-1/2 !relative opacity-80"
            />
          </div>
        )}
      </div>
    </section>
  );
}
