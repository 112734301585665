import React from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import TestimonialsDesktop from './TestimonialsDesktop';
import TestimonialsMobile from './TestimonialsMobile';

export default function Testimonials({ data }) {
  const subtitle = _.get(data, 'subtitle', null);
  const title = _.get(data, 'title', null);
  const items = _.get(data, 'items', []);

// hidden for time being

  return (
    <section
      id="testimonials"
      className="hidden prose overflow-hidden bg-background-brand-2 pb-12 pt-8 lg:py-30">
      <div className="relative mx-auto flex w-full max-w-409 flex-col gap-y-6 px-8 md:gap-y-16 3xl:px-0">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          viewport={{ once: true }}
          className="flex w-full flex-col items-center justify-center gap-y-2 md:gap-y-6">
          {subtitle && <span className="body-1-variant">{subtitle}</span>}
          {title && <h2 className="text-center">{title}</h2>}
        </motion.div>
        <TestimonialsDesktop data={items} />
        <TestimonialsMobile data={items} />
      </div>
    </section>
  );
}
