import _ from 'lodash';

export default function getTranslationByKey(key, translations, lang) {
  // Check if translations is empty
  if (_.isEmpty(translations)) return key;

  // Find the translation item by comparing kebab-cased keys
  const filteredItem = _.find(translations, (val) =>
    _.isEqual(_.kebabCase(_.get(val, 'content.key', '')), _.kebabCase(key)),
  );

  // If no matching item is found, return the original key
  if (_.isEmpty(filteredItem)) return key;

  // Determine if the language is Arabic
  const isArabic = _.isEqual(lang, 'ar');

  // Return the translation value for Arabic or the original key
  return isArabic ? _.get(filteredItem, 'content.value', key) : key;
}
