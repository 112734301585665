import React, { useContext, lazy } from 'react';
import _ from 'lodash';
import DesktopMap from '@/components/LocationsMaps/DesktopMap';
import MobileMap from '@/components/LocationsMaps/MobileMap';
import Context from '@/context';
import useWindowResize from '@/hooks/use-window-resize';

const SvgMap = lazy(() => import('@/assets/svg/location-map-en-v2.svg'));
const SvgMapAr = lazy(() => import('@/assets/svg/location-map-ar-v2.svg'));

export default function LocationMaps({ data, id, specificData }) {
  const { lang } = useContext(Context);
  const isArabic = lang === 'ar';
  const MapComp = isArabic ? SvgMapAr : SvgMap;
  const { width } = useWindowResize();
  const isDesktop = width >= 1024;
  const poi = _.get(specificData, 'poi', []);

  return (
    <section id={id} className="pb-12 lg:pb-30">
      {isDesktop ? (
        <DesktopMap data={data} items={poi} MAP={MapComp} />
      ) : (
        <MobileMap data={data} items={poi} MAP={MapComp} isArabic={isArabic} isDesktop={isDesktop}/>
      )}
    </section>
  );
}

