import React from 'react';
import Link from 'next/link';
import _ from 'lodash';
import { motion } from 'framer-motion';

import { buttonVariants } from '@/components/ui/button';
import ResponsiveImage from '@/components/layouts/ResponsiveImage';

export default function LocationShowcaseItem({ data }) {
  const title = _.get(data, 'content.title', '');
  const description = _.get(data, 'content.description', '');
  const link = _.get(data, 'content.link', null);
  const blockImage = _.get(data, 'content.blockImage[0]', null);

  return (
    <div className="flex flex-col md:flex-row gap-7 lg:gap-28 xl:gap-40">
      <div className="w-full max-w-92 md:max-w-235.5">
        {_.isEmpty(blockImage) || (  
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            viewport={{ once: true }}
            className="w-full h-full"
          >
            <ResponsiveImage
              desktopImage={blockImage?.content?.image?.url}
              mobileImage={blockImage?.content?.mobileImage?.url}
              alt={title}
              className="object-cover md:!h-auto"
            />
          </motion.div>        
        )}
      </div>

      <div className="flex flex-col justify-center md:gap-8">
        <div className="flex flex-col gap-2 md:gap-8">
          {_.isEmpty(title) || (  
            <h2>{title}</h2>
          )}

          {_.isEmpty(description) || (
            <div className="w-full md:w-97.5">
              <p>{description}</p>
            </div>
          )}
        </div>

        {_.isEmpty(link) || (  
          <div className="w-fit mt-7 md:mt-0">
            <Link
              href={link.url}
              target={link.target}
              className={`${buttonVariants({ variant: 'secondary' })} !w-fit gap-2 rtl:text-right rtl:leading-medium`}>
              {link.name}
              <i className="one-central-external-link rtl:-rotate-90" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}