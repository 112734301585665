'use client';

import { useCallback, useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import _ from 'lodash';

const useCustomCursor = () => {
  // const [isDragging, setIsDragging] = useState(false);
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const hoverCursor = document.createElement('div');
    hoverCursor.setAttribute('id', 'hoverCursor');

    document.body.classList.add('hover-cursor-on');
    document.body.appendChild(hoverCursor);

    const handleMouseMove = (event) => {
      const currCursor = document.querySelector('#hoverCursor');
      if (currCursor && currCursor.style) {
        const cursorX = event.clientX;
        const cursorY = event.clientY;

        currCursor.style.transform = `translate(${cursorX}px, ${cursorY}px)`;
      }
    };

    window.addEventListener('pointermove', handleMouseMove);

    return () => {
      hoverCursor.remove();
      document.body.classList.remove('hover-cursor-on');
      window.removeEventListener('pointermove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    document.body.classList.remove('hover-cursor-on');
  }, [pathname, searchParams]);

  const toggleCursor = useCallback((isEnabled) => {
    // const hoverCursor = document.querySelector('#hoverCursor');

    // if (hoverCursor) {
    //   if (isEnabled && window.innerWidth >= 1024) {
    //     _.set(hoverCursor.style, 'opacity', '1');
    //     document.body.classList.add('hover-cursor-on');
    //   } else {
    //     _.set(hoverCursor.style, 'opacity', '0');
    //     document.body.classList.remove('hover-cursor-on');
    //   }
    // }
  }, []);

  return { toggleCursor };
};

export default useCustomCursor;
