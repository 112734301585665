import React, { useEffect, useState } from 'react';
import BoxedContainer from '@/components/layouts/BoxedContainer';
import { buttonVariants } from '@/components/ui/button';
import _ from 'lodash';
import EventCard from '@/components/layouts/EventCard';
import useWindowResize from '@/hooks/use-window-resize';
import getTranslationByKey from '@/helpers/getTranslationByKey';
import { useContext } from 'react';
import Context from '@/context';

function EventsList({ id, data, specificData, dir }) {
  const MAX = 6;
  const [selected, setSelected] = useState(null);
  const { translations, lang } = useContext(Context);
  const events = _.get(specificData, 'events', []);
  const [filteredEvents, setFilteredEvents] = useState(
    _.size(events) <= MAX ? events : _.slice(events, 0, MAX),
  );
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(_.size(events) > MAX);
  const title = _.get(data, 'title', '');
  const text = _.get(data, 'text', '');

  const handleLoadMore = () => setLoadMore(true);

  const handleClick = (item) => {
    setSelected((prev) => {
      if (_.isEqual(prev, item)) return null;
      return item;
    });
  };

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = _.size(filteredEvents);
      const isGrater = +currentLength < _.size(events);
      const nextResults = isGrater ? _.slice(events, +currentLength, +currentLength + MAX) : [];
      setFilteredEvents((prev) => [...prev, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  useEffect(() => {
    const isGrater = _.size(filteredEvents) < _.size(events);
    setHasMore(isGrater);
  }, [filteredEvents]);

  return (
    <section id={id} className="prose">
      <BoxedContainer className="flex flex-col gap-16">
        <div className="flex flex-col justify-center gap-6 text-center">
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
        <div className="grid auto-rows-fr grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
          {_.map(filteredEvents, (item, index) => (
            <div
              key={index}
              className="min-h-92 lg:min-h-104 xl:min-h-116 2xl:min-h-130 3xl:min-h-149">
              <EventCard
                data={item}
                clickHandler={() => handleClick(item)}
                open={_.isEqual(item, selected)}
                dir={dir}
              />
            </div>
          ))}
        </div>
        {hasMore && (
          <div className="flex w-full justify-center">
            <button
              type="button"
              onClick={handleLoadMore}
              className={buttonVariants({ variant: 'secondary' })}>
              {getTranslationByKey('Load More', translations, lang)}
            </button>
          </div>
        )}
      </BoxedContainer>
    </section>
  );
}

export default EventsList;
