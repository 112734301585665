/* eslint-disable import/no-unresolved */
'use client';

import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { motion } from 'framer-motion';
import 'swiper/css/pagination';
import _ from 'lodash';
import useCustomCursor from '@/hooks/use-custom-cursor';
import Image from 'next/image';
import Icons from '../layouts/Icons';

export default function TestimonialsDesktop({ data }) {
  const { toggleCursor } = useCustomCursor();
  const [isPlaying, setIsPlaying] = useState({});
  const [isHovered, setIsHovered] = useState({});
  const videoRefs = useRef([]);

  useEffect(() => {
    toggleCursor(false);
    return () => toggleCursor(false);
  }, []);

  const handlePlayPauseClick = (index) => {
    if (isPlaying[index]) {
      videoRefs.current[index].pause();
    } else {
      videoRefs.current[index].play();
    }
    setIsPlaying((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleMouseEnter = (index) => {
    setIsHovered((prev) => ({ ...prev, [index]: true }));
  };

  const handleMouseLeave = (index) => {
    setIsHovered((prev) => ({ ...prev, [index]: false }));
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: 0.4 }}
      viewport={{ once: true }}
      className="hidden md:grid grid-cols-2 grid-flow-col gap-7"
    >
      {_.isEmpty(data) ||
        _.map(data, (item, index) => (
          <div key={index} className="flex flex-col gap-y-12">
            {_.isEmpty(item.content.videoUrl) || (
              <div
                className="relative"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <video
                  ref={(el) => (videoRefs.current[index] = el)}
                  muted
                  playsInline
                  loop
                  preload="metadata"
                  poster={item.content.videoPoster}
                  crossOrigin="anonymous"
                  className="max-h-113 aspect-video w-full rounded-sm"
                  >
                  <source 
                  src={`/api/proxy-video?videoPath=${item.content.videoUrl}`} 
                  type="video/mp4" />
                </video>
                {(isHovered[index] || !isPlaying[index]) && (
                  <button
                    type='button'
                    className="absolute inset-0 flex items-center justify-center"
                    onClick={() => handlePlayPauseClick(index)}
                  >
                    <Icons icon={isPlaying[index] ? "pause-icon" : "play-icon"} />
                  </button>
                )}
              </div>
            )}

            <div className="flex flex-row items-center gap-x-8">
              <div className="bg-primary border-2 border-primary-black flex 
              h-18 w-18 items-center justify-center rounded-full">
                {_.isEmpty(item.content.personImage) || (
                  <Image
                    src={item.content.personImage.url}
                    alt={item.content.personName}
                    fill
                    className="!relative h-full w-full"
                  />
                )}
              </div>
              <div className="flex flex-col gap-2">
                <h5 className="!font-normal !tracking-normal">{item.content.personName}</h5>
                <p className="!text-text-accent">{item.content.personPosition}</p>
              </div>
            </div>
          </div>
        ))}
    </motion.div>
  );
}
