'use client';

import React, { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import _ from 'lodash';
import HeaderMobileMenu from './HeaderMobileMenu';
import Icons from '../layouts/Icons';

export default function HeaderMobile({
  data,
  bottomLinks,
  instagram,
  footerBackgroundImage,
  position,
  lang,
  isHome,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const regions = {
    ar: { oppositeLabel: 'EN', oppositeLang: 'en' },
    en: { oppositeLabel: 'AR', oppositeLang: 'ar' },
  };
  const langLabel = _.get(regions, `${lang}.oppositeLabel`, 'ar');
  const pathname = usePathname();
  const slug = _.chain(pathname).split('/').filter(Boolean).value();
  const parentPath = `/${_.chain(slug).take(2).join('/').value()}/`;
  const path = `/${_.chain(slug).join('/').trim('/').value()}/`;
  _.set(slug, 0, regions[lang]?.oppositeLang);
  const redirectionPath = _.join(slug, '/');

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="relative z-[100] flex flex-row items-center justify-between gap-10">
        <Link href={`/${lang}`} arial-label="Home Page">
          <Icons
            icon="logo"
            className={` ${isMenuOpen ? 'h-20 svg-path:!fill-black' : 'h-20'} ${position >= 50 ? 'svg-path:!fill-black' : ''} ${isHome ? 'svg-path:fill-white' : ''}`}
          />
        </Link>
        <div className="flex flex-row gap-4">
          <Link href={`/${lang}/search`} aria-label="Search">
            <Icons
              icon="search"
              className={`h-8 w-8 ${isMenuOpen ? 'svg-rect:!fill-black svg-path:!fill-white' : ''} ${position >= 50 ? 'svg-rect:!fill-black svg-path:!fill-white' : ''} ${isHome ? 'svg-rect:fill-white svg-path:fill-black' : ''}`}
            />
          </Link>
          <Link
            aria-label={langLabel}
            href={`/${redirectionPath}`}
            className={`flex h-8 w-8 items-center justify-center rounded-full text-[12px] font-bold uppercase leading-[100%] ${isMenuOpen ? '!bg-black !text-white' : isHome && position <= 114 ? 'bg-white text-black' : '!bg-black text-white'} `}>
            {langLabel}
          </Link>
          <button type="button" onClick={toggleMenu} aria-label='Toggle'>
            <Icons
              icon={`${isMenuOpen ? 'menu-close' : 'menu-open'}`}
              className={`${position >= 50 ? 'svg-path:stroke-black' : ''} ${isHome ? '' : 'svg-path:stroke-black'} `}
            />
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <HeaderMobileMenu
          data={data}
          parentPath={parentPath}
          path={path}
          lang={lang}
          bottomLinks={bottomLinks}
          instagram={instagram}
          footerBackgroundImage={footerBackgroundImage}
        />
      )}
    </>
  );
}
