import React from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import TestimonialsMobileItem from './TestiomonialsMobileItem';

export default function TestimonialsMobile({ data }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: 0.4 }}
      viewport={{ once: true }}
      className="block md:hidden">
      {_.isEmpty(data) || (
        <div className="flex flex-col gap-y-8">
          {_.map(data, (item, index) => (
            <TestimonialsMobileItem data={item} key={index} />
          ))}
        </div>
      )}
    </motion.div>
  );
}
