/* eslint-disable import/no-unresolved */

'use client';

import React, { useState, useEffect, useRef, useContext } from 'react';
import _ from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { motion, useInView } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import useCustomCursor from '@/hooks/use-custom-cursor';
import Context from '@/context';
import Head from 'next/head';

export default function TwoColumnTextCTAWithSlider({ data }) {
  const subtitle = _.get(data, 'subtitle', null);
  const title = _.get(data, 'title', null);
  const text = _.get(data, 'htmlText', null);
  const link = _.get(data, 'link', null);
  const items = _.get(data, 'items', []);
  const hasSlider = _.get(data, 'hasSlider', false);
  const fullscreen = _.get(data, 'fullscreen', false);
  if (_.isEmpty(items)) return null;
  const groupedItems = _.chunk(items, 1);
  const totalLength = groupedItems?.length;
  const [activeIndex, setActiveIndex] = useState(1);
  const percentageWidth = (activeIndex / totalLength) * 100;
  const [swiperInstance, setSwiperInstance] = useState(null);
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { amount: 0.5 });
  const { lang } = useContext(Context);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex + 1);
  };

  const { toggleCursor } = useCustomCursor();

  useEffect(() => {
    toggleCursor(false);
    return () => toggleCursor(false);
  }, []);

  useEffect(() => {
    if (swiperInstance?.autoplay) {
      if (isInView) {
        swiperInstance.autoplay?.start();
      } else {
        swiperInstance.autoplay?.stop();
      }
    }
  }, [isInView, swiperInstance]);

  const breakpoints = {
    0: {
      slidesPerView: 1.6,
      slidesPerGroup: 1,
      spaceBetween: 20,
      slidesOffsetBefore: 32,
      slidesOffsetAfter: 32,
    },
    640: {
      slidesPerView: 2.2,
      slidesPerGroup: 1,
      spaceBetween: 20,
      slidesOffsetBefore: 32,
      slidesOffsetAfter: 32,
    },
    1024: {
      slidesPerView: 2.2,
      slidesPerGroup: 1,
      spaceBetween: 30,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
    },
    1280: {
      slidesPerView: 2.5,
      slidesPerGroup: 1,
      spaceBetween: 56,
    },
  };

  return (
    <section
      id="two-column-text-cta-with-slider"
      ref={sectionRef}
      className="prose relative flex flex-col overflow-hidden bg-background-base py-12 md:py-30 lg:flex-row">
      <div
        className={`flex items-center lg:px-6 ${hasSlider ? 'bottom-0 top-0 xl:absolute' : fullscreen ? 'w-full justify-end' : 'w-full justify-center'} xl:px-0 ltr:left-0 ltr:right-0 rtl:left-0 rtl:right-0`}>
        <div
          className={`relative z-10 w-full max-w-409 px-8 3xl:px-0 ${!hasSlider ? 'flex gap-x-6 2xl:gap-x-16' : 'mx-auto'}`}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            viewport={{ once: true }}
            className="flex flex-row gap-x-36">
            <div className="flex flex-col gap-y-12 lg:max-w-100 xl:max-w-137">
              <div className="flex max-w-100 flex-col gap-6 md:gap-y-8">
                <div className="flex flex-col gap-2 md:gap-y-6">
                  {subtitle && <span className="body-1-variant">{subtitle}</span>}
                  {title && <h2>{title}</h2>}
                </div>
                {text && <div className="" dangerouslySetInnerHTML={{ __html: text }} />}
              </div>
              {_.isEmpty(items) ||
                (hasSlider && (
                  <div className="hidden flex-row items-center gap-2 lg:flex">
                    {_.map(items, (activeItem, activeItemIndex, indexButton) => (
                      <button
                        aria-label={`Slider Btn ${activeItem}`}
                        key={indexButton}
                        type="button"
                        onClick={() => {
                          if (swiperInstance) {
                            swiperInstance.slideToLoop(activeItemIndex);
                          }
                        }}
                        className={`h-1 w-6 ${activeIndex === activeItemIndex + 1 ? 'bg-text-accent' : 'bg-background-tertiary'}`}
                      />
                    ))}
                  </div>
                ))}

              {_.isEmpty(link) || (
                <div className="hidden lg:block">
                  <Link
                    href={replaceUrl(link.url)}
                    target={link.target}
                    className={cn(
                      buttonVariants({ variant: 'secondary' }),
                      'text-cta-with-slider-button w-fit text-text-accent',
                      link.target === '_blank' && 'external-link',
                    )}
                    aria-label="text-cta-with-slider-button">
                    {link.name}
                  </Link>
                </div>
              )}
            </div>
          </motion.div>

          {!hasSlider && (
            <div className="hidden md:mb-4 lg:flex lg:gap-x-5 2xl:gap-x-10 ltr:ml-auto ltr:mr-0 rtl:ml-0 rtl:mr-auto">
              {_.isEmpty(items) ||
                _.map(items, (item, indexSlider) => (
                  <div
                    key={indexSlider}
                    className="group flex gap-x-8 gap-y-8 lg:w-54 xl:w-84 xl:max-w-100 xl:gap-x-14">
                    <Head>
                      <link
                        fetchPriority="high"
                        rel="preload"
                        as="image"
                        href={item.content?.image?.url}
                      />
                    </Head>
                    {_.isEmpty(item.content.link) ? (
                      <div className="flex w-full flex-col gap-y-8">
                        {_.isEmpty(item.content.image) || (
                          <Image
                            src={item.content.image.url}
                            alt={`CTA Image ${item.content.title}`}
                            fill
                            className="!relative h-full max-h-158 w-full rounded-md object-cover"
                          />
                        )}
                        <h4 className="group-hover:text-text-accent group-hover:transition-all group-hover:duration-200">
                          {item.content.title}
                        </h4>
                      </div>
                    ) : (
                      <Link
                        href={replaceUrl(item.content.link.url)}
                        target={item.content.link.target}
                        className="flex w-full flex-col gap-y-8 no-underline">
                        {_.isEmpty(item.content.image) || (
                          <Image
                            src={item.content.image.url}
                            placeholder="blur"
                            blurDataURL={`${item.content.image.url}?width=39`}
                            quality={100}
                            alt={`CTA Image ${item.content.title}`}
                            fill
                            className="!relative h-full max-h-158 w-full rounded-md object-cover"
                          />
                        )}
                        <h4 className="group-hover:text-text-accent group-hover:transition-all group-hover:duration-200">
                          {item.content.title}
                        </h4>
                      </Link>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      {hasSlider && (
        <div className="z-10 hidden w-[55%] md:mb-4 lg:block ltr:ml-auto ltr:mr-0 rtl:ml-0 rtl:mr-auto">
          {_.isEmpty(items) || (
            <Swiper
              breakpoints={breakpoints}
              onSwiper={setSwiperInstance}
              speed={600}
              modules={[Autoplay]}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop
              onSlideChange={handleSlideChange}
              className="mySwiper cards swiper-slide:!opacity-50 swiper-slide-active:!opacity-100">
              {groupedItems.map((group, indexItemSlider) => (
                <SwiperSlide
                  key={indexItemSlider}
                  className="transition-all delay-200 duration-1000">
                  <div className="flex gap-x-8 gap-y-8 xl:gap-x-14">
                    {group.map(
                      (item, itemIndex) =>
                        _.isEmpty(item.content.link) || (
                          <Link
                            href={replaceUrl(item.content.link.url)}
                            key={itemIndex}
                            className="group flex w-full flex-col gap-y-8 no-underline">
                            {_.isEmpty(item.content.image) || (
                              <>
                                <Head>
                                  <link
                                    fetchPriority="high"
                                    rel="preload"
                                    as="image"
                                    href={item.content.image?.url}
                                  />
                                </Head>
                                {item.content.image && (
                                  <Image
                                    src={`${item.content.image.url}?width=390`}
                                    placeholder="blur"
                                    blurDataURL={`${item.content.image?.url}?width=39`}
                                    quality={100}
                                    alt={`Slider CTA Image ${item.content.title}`}
                                    fill
                                  className="!relative h-full max-h-158 w-full rounded-md object-cover"
                                />
                              )}
                            </>
                            )}
                            <h3 className="group-hover:text-text-accent group-hover:transition-all group-hover:duration-200">
                              {item.content.title}
                            </h3>
                          </Link>
                        ),
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      )}

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.4 }}
        viewport={{ once: true }}
        className="cards z-10 pt-10 lg:hidden">
        <Swiper breakpoints={breakpoints}>
          {_.isEmpty(items) ||
            items.map((item, indexBreaks) => (
              <SwiperSlide key={indexBreaks} className="">
                <div className="flex gap-y-4">
                  {_.isEmpty(item.content.link) ? (
                    <div className="flex w-full flex-col gap-y-4 no-underline">
                      {_.isEmpty(item.content.image) || (
                        <>
                          <Head>
                            <link
                              fetchPriority="high"
                              rel="preload"
                              as="image"
                              href={item.content.image?.url}
                            />
                          </Head>

                          {item.content.image && (
                            <Image
                              src={item.content.image.url}
                              placeholder="blur"
                              blurDataURL={`${item.content.image.url}?width=33`}
                              quality={100}
                              alt={`CTA Image ${item.content.title}`}
                              fill
                              className="rounded-4 !relative h-full max-h-50 w-full rounded-sm object-cover"
                            />
                          )}
                        </>
                      )}
                      <h3 className="tracking-normal">{item.content.title}</h3>
                    </div>
                  ) : (
                    <Link
                      href={replaceUrl(item.content.link.url)}
                      target={item.content.link.target}
                      className="flex w-full flex-col gap-y-4 no-underline">
                      {_.isEmpty(item.content.image) || (
                        <>
                          <Head>
                            <link
                              fetchPriority="high"
                              rel="preload"
                              as="image"
                              href={item.content.image?.url}
                            />
                          </Head>
                          {item.content.image && (
                            <Image
                              src={item.content.image.url}
                              placeholder="blur"
                              blurDataURL={`${item.content.image.url}?width=33`}
                              quality={100}
                              alt={`CTA Image ${item.content.title}`}
                              fill
                              className="rounded-4 !relative h-full max-h-50 w-full rounded-sm object-cover"
                            />
                          )}
                        </>
                      )}
                      <h3 className="tracking-normal">{item.content.title}</h3>
                    </Link>
                  )}
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </motion.div>
      {_.isEmpty(link) || (
        <div className="block px-8 pt-8 lg:hidden">
          <Link
            href={replaceUrl(link.url)}
            target={link.target}
            className={cn(
              buttonVariants({ variant: 'secondary' }),
              'text-cta-with-slider-button text-text-accent',
              link.target === '_blank' && 'external-link',
            )}
            aria-label="text-cta-with-slider-button">
            {link.name}
          </Link>
        </div>
      )}
    </section>
  );
}
