import React from 'react';
import { TransformComponent, useControls } from 'react-zoom-pan-pinch';
import Icons from '@/components/layouts/Icons';

function MobileMapTransform({ isArabic, MAP }) {
  const { zoomIn, zoomOut } = useControls();

  return (
    <>
      <div className='bg-gradient-to-b from-transparent to-white h-[128px] absolute z-40 pointer-events-none top-[425px] w-full' />
      <div
        data-arabic={isArabic}
        className="absolute z-[100] flex flex-col gap-4 data-[arabic=false]:bottom-[12.5vw] data-[arabic=false]:right-3 data-[arabic=true]:bottom-3 data-[arabic=true]:left-3 md:data-[arabic=false]:bottom-[13.5vw]">
        <button type="button" onClick={() => zoomIn()}>
          <Icons icon="zoom-in" />
        </button>
        <button type="button" onClick={() => zoomOut()}>
          <Icons icon="zoom-out" />
        </button>
      </div>
      <TransformComponent
        wrapperClass="!max-w-screen !w-full !h-[552px]"
        contentClass="w-full h-full">
        <MAP id="location-map" width={1206} height={600} />
      </TransformComponent>
    </>
  );
}

export default MobileMapTransform;
