import React, { useContext } from 'react';
import IbisOneCentralDesktop from './IbisOneCentralDesktop';
import IbisOneCentralMobile from './IbisOneCentralMobile';
import Context from '@/context';

export default function IbisOneCentral({ data, id }) {
  const { lang } = useContext(Context);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);
  return (
    <section id={id} className="prose overflow-hidden py-14 lg:py-25 lg:pb-50">
      <IbisOneCentralDesktop data={data} replaceUrl={replaceUrl}/>
      <IbisOneCentralMobile data={data} replaceUrl={replaceUrl}/>
    </section>
  );
}
