import React, { useState, useEffect } from 'react';
import useCustomCursor from '@/hooks/use-custom-cursor';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Image from 'next/image';
import _ from 'lodash';
import useElementSize from '@/hooks/useElementSize';

export default function EventsTwentyFiveHoursDesktop({ data }) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const items = _.get(data, 'items', []);
  const { toggleCursor } = useCustomCursor();

  useEffect(() => {
    toggleCursor(false);
    return () => toggleCursor(false);
  }, [toggleCursor]);

  const handleClick = (index) => {
    setSelectedIndex((prev) => (prev === index ? null : index));
  };

  const [ref, width, height] = useElementSize();

  return (
    _.isEmpty(items) || (
      <div
        className="hidden pt-16 md:block"
        onMouseEnter={() => toggleCursor(true)}
        onMouseLeave={() => toggleCursor(false)}>
        <Swiper
          speed={600}
          spaceBetween={24}
          slidesPerView='auto'
          className="!pb-12 last:ml-8 last:!pb-0  last:lg:ml-16">
          {items.map((item, index) => {
            const open = selectedIndex === index;
            return (
              <SwiperSlide key={index} className='aspect-[528/739] max-w-[325px] md:max-w-[375px] xl:max-w-[425px] 2xl:max-w-[475px] 2xll:max-w-[528px]'>
                <button
                  type="button"
                  onClick={() => handleClick(index)}
                  className="h-fit w-full flex aspect-[528/739">
                  <article className="relative w-full overflow-hidden aspect-[528/739] rounded">
                    {item?.content?.featuredImage && (
                      <Image
                        src={item?.content?.featuredImage?.url}
                        alt="background layer"
                        fill
                        className="!object-cover aspect-[528/739]"
                        priority
                      />
                    )}

                    <div
                      data-open={open}
                      className={`overlay-card relative z-10 flex h-full w-full flex-col 
                      justify-start overflow-auto transition-transform aspect-[528/739] max-h-[739px] 
                      ${open 
                        ? 'transform-none' 
                        : 'translate-y-[calc(100%-78px)] lg:translate-y-[calc(100%-92px)]'} 
                      ${open 
                      ? 'bg-text-accent' 
                      : 'bg-text-primary'}`}>
                      <div
                        data-open={open}
                        className="flex h-18 w-full flex-col justify-between p-6 text-text-invert lg:h-23 lg:p-7">
                        <div className="flex w-full items-center justify-between">
                          {item?.content?.title && (
                            <h4
                              data-open={open}
                              className="text-start ltr:!text-clamp16to32 rtl:!text-clamp18to24 !font-normal 
                              !tracking-normal text-text-invert transition-all 
                              data-[open=false]:line-clamp-1 leading-default">
                              {item?.content?.title}
                            </h4>
                          )}
                          <i
                            data-open={open}
                            className={`transition-transform duration-150 
                            ${open ? 'rotate-180' : 'rotate-0'}`}>
                            <i className="one-central-arrow-small text-[9px] text-text-invert" />
                          </i>
                        </div>
                      </div>
                      <div
                        ref={ref}
                        className="flex w-full flex-col px-6 pb-6 lg:px-7 lg:pb-7">
                        {item?.content?.descriptionText && (
                          <p 
                          className="text-start text-clamp16to18 font-extralight leading-medium 
                          !tracking-normal text-text-invert [&>strong]:text-white" 
                          dangerouslySetInnerHTML={{ __html: item?.content?.descriptionText }} />
                        )}
                      </div>
                    </div>
                  </article>
                </button>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    )
  );
}
