import React, { useState } from 'react';
import Image from 'next/image';
import { motion } from 'framer-motion';
import _ from 'lodash';
import useElementSize from '@/hooks/useElementSize';
import Icons from '../layouts/Icons';
import getTranslationByKey from '@/helpers/getTranslationByKey';
import { useContext } from 'react';
import Context from '@/context';

export default function EventsTwentyFiveHoursMobile({ data }) {
  const items = _.get(data, 'items', []);
  const [visibleCount, setVisibleCount] = useState(4); // Initially show 4 cards
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [ref, width, height] = useElementSize();

  const handleClick = (index) => {
    setSelectedIndex((prev) => (prev === index ? null : index));
  };

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 4); // Load 4 more cards
  };

  const visibleItems = items.slice(0, visibleCount);
  const { translations, lang } = useContext(Context);

  return (
    <div className="block md:hidden">
      {_.isEmpty(items) || (
        <div className="flex flex-col gap-4 px-8 pt-10">
          {_.map(visibleItems, (item, index) => {
            const open = selectedIndex === index;
            return (
              <button
                type="button"
                onClick={() => handleClick(index)}
                key={index}
                className="min-62">
                <article className="relative h-[394px] w-full 
                overflow-hidden aspect-[528/739] flex rounded">
                  {item?.content?.featuredImage && (
                    <Image
                      src={item?.content?.featuredImage?.url}
                      alt="background layer"
                      fill
                      className="!object-cover object-top aspect-[528/739]"
                      priority
                    />
                  )}

                  <div
                    data-open={open}
                    className={`overlay-card relative z-10 flex h-full w-full flex-col 
                    justify-end overflow-auto transition-transform 
                    ${open ? 'transform-none' : 'translate-y-[calc(100%-58px)]'} 
                    ${open ? 'bg-text-accent' : 'bg-text-primary'}`}>
                    <div
                      data-open={open}
                      className="flex h-auto w-full flex-col justify-between p-4 
                      text-text-invert lg:p-7">
                      <div className="flex w-full items-center justify-between">
                        {item?.content?.title && (
                          <h4
                            data-open={open}
                            className="text-start ltr:!text-clamp16to32 rtl:!text-clamp18to28 !font-normal 
                            !tracking-normal text-text-invert transition-all 
                            data-[open=false]:line-clamp-1 leading-default">
                            {item?.content?.title}
                          </h4>
                        )}
                        <i
                          data-open={open}
                          className="transition-all data-[open=false]:rotate-0 
                          data-[open=true]:rotate-180">
                          <i className="one-central-arrow-small text-[9px] text-text-invert" />
                        </i>
                      </div>
                    </div>
                    <div ref={ref} className="flex h-full w-full grow flex-col px-4 pb-4">
                      {item?.content?.descriptionText && (
                        <p 
                        className="text-start text-clamp16to18 font-extralight 
                        leading-medium text-text-invert
                        !tracking-normal [&>strong]:text-white" 
                        dangerouslySetInnerHTML={{ __html: item?.content?.descriptionText }} />
                      )}
                    </div>
                  </div>
                </article>
              </button>
            );
          })}
        </div>
      )}
      {visibleCount < items.length && (
        <div className="flex items-center justify-center pt-10">
          <button
            onClick={handleLoadMore}
            className="rounded-sm uppercase py-4 md:py-5 px-3 md:px-6 lg:px-8 
            transition-all duration-300 no-underline
            flex justify-center items-center text-center bg-white border-[1.5px] border-text-accent text-text-accent lg:hover:text-white lg:hover:bg-text-accent w-fit">
             {getTranslationByKey('Load More', translations, lang)}
          </button>
        </div>
      )}
    </div>
  );
}
