import React from 'react';
import _ from 'lodash';

export default function CertificationRecognitionItemAward({ data, border, awards }) {
  const title = _.get(data, 'content.title', null);
  const description = _.get(data, 'content.description', null);
  const date = _.get(data, 'content.date', null);

  return (
    <div
      data-border={border}
      className={`flex xl:max-w-60 flex-col sm:w-full 
        xl:ltr:pr-4 [&:not(:last-child)]:data-[border=true]:xl:ltr:border-r
        xl:rtl:pl-4 [&:not(:last-child)]:data-[border=true]:xl:rtl:border-l 
        ${
        _.size(awards) > 2
          ? 'w-[calc(50%-12px)]'
          : 'w-fit text-center lg:ltr:text-left lg:rtl:text-right'
      }`}>
      {_.isEmpty(description) || <p className="body-1-variant text-nowrap text-ellipsis pb-2">{description}</p>}
      {_.isEmpty(title) || <p className="subtitle pb-3 !font-medium leading-medium max-w-[220px]">{title}</p>}
      {_.isEmpty(date) || <p className="body-2 !font-thin">{date}</p>}
    </div>
  );
}
