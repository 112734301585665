'use client';

import React, { useCallback, useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import axios from 'axios';
import Select from 'react-select';

import Pagination from '@/components/layouts/Pagination';
import getTranslationByKey from '@/helpers/getTranslationByKey';
import Context from '@/context';
import Spinner from '@/components/layouts/Spinner';
import SearchResultsItem from './SearchResultsItem';

export default function SearchResults({ data, id, dir }) {
  const [results, setResults] = useState([]);
  const [term, setTerm] = useState('');
  const itemsPerPage = 10;
  const { translations, lang } = useContext(Context);
  const allowedTypes = ['oneCentralPage', 'oneCentralHome', 'oneCentralRetail'];
  const sortOptions = [
    { value: 'a-z', label: 'A-Z' },
    { value: 'z-a', label: 'Z-A' },
    // { value: 'recent', label: getTranslationByKey('Most Recent', translations, lang) },
  ];
  const [selectedSort, setSelectedSort] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const doSearch = (term) => {
    if (!term) return;

    setIsLoading(true);

    axios({
      method: 'get',
      url: `https://cdn.umbraco.io/content/search?term=${term}&page=1&pageSize=1000`,
      headers: {
        'umb-project-alias': process.env.NEXT_PUBLIC_UMBRACO_ENVIRONMENT,
        'Content-Type': 'application/json',
        'Accept-Language': dir === 'rtl' ? 'ar' : 'en-US',
      },
    })
      .then((res) => {
        const allowedResults =
          res.data._embedded && res.data._embedded.content
            ? res.data._embedded.content.filter((r) => allowedTypes.includes(r.contentTypeAlias))
            : [];
        setResults([...allowedResults]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter' && term) {
      doSearch(term);
    }
  };

  const handleSearchClick = () => {
    doSearch(term);
  };

  const sortResults = (results, sortOption) => {
    if (!results) return [];

    switch (sortOption) {
      case 'a-z':
        return [...results].sort((a, b) => a.title.localeCompare(b.title));
      case 'z-a':
        return [...results].sort((a, b) => b.title.localeCompare(a.title));
      case 'recent':
        return [...results].sort((a, b) => new Date(b._updateDate) - new Date(a._updateDate));
      default:
        return results;
    }
  };

  useEffect(() => {
    if (selectedSort) {
      const sortedResults = sortResults(results, selectedSort);
      setResults(sortedResults);
    }
  }, [selectedSort]);

  return (
    <section id={id} className="bg-base prose relative w-full gap-y-20 pb-12 lg:pb-30">
      <div className="relative flex h-22 w-full bg-background-primary">
        <div className="relative h-full w-full">
          <input
            type="text"
            className="input h-full w-full border bg-background-primary pl-6 pr-12 placeholder:text-secondary"
            placeholder={getTranslationByKey('Search for keyword', translations, lang)}
            value={term}
            onChange={handleChange}
            onKeyDown={handleEnterPress}
          />

          <div className="absolute right-6 top-0 flex h-full items-center justify-center">
            <button type="button" onClick={handleSearchClick}>
              <i className="one-central-search text-[18px]" />
            </button>
          </div>
        </div>

        <Select
          unstyled
          className="input hidden h-full w-full max-w-64 rounded-none border bg-background-primary text-secondary lg:flex"
          classNames={{
            control: () => 'w-full h-full !cursor-pointer px-6',
            indicatorSeparator: () => 'hidden',
            menu: () => 'flex flex-col w-full max-w-64 border',
            option: () =>
              'bg-background-primary flex py-4 px-6 border-b last:border-none !cursor-pointer',
          }}
          options={sortOptions}
          isSearchable={false}
          placeholder={getTranslationByKey('Sort by', translations, lang)}
          onChange={(_option) => setSelectedSort(_option.value)}
        />
      </div>

      <div className="relative mx-auto flex max-w-92 flex-col divide-y pt-8 md:max-w-155 lg:max-w-235.5 lg:pt-25 2xl:max-w-339">
        {isLoading ? (
          <Spinner />
        ) : (
          <Pagination
            data={results}
            showCount={false}
            RenderComponent={SearchResultsItem}
            itemsWrapperClass="w-full h-auto flex flex-col gap-y-8 lg:gap-y-14 lg:pt-12"
            wrapperClass="lg:pt-10 lg:pb-10"
            prevLabel="first"
            nextLabel="last"
            dataLimit={itemsPerPage}
          />
        )}
      </div>
    </section>
  );
}

