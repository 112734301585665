'use client';

import React from 'react';
import _ from 'lodash';
import 'swiper/css';
import 'swiper/css/pagination';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Head from 'next/head';

export default function TextContentWithHorizontalSlider({ data, id }) {
  const title = _.get(data, 'title', null);
  const description = _.get(data, 'blockDescription', null);
  const images = _.get(data, 'blockImage', null);

  return (
    <section
      id={id}
      className="prose relative flex flex-col overflow-hidden bg-background-base py-12 md:py-30 lg:flex-row">
      <div className="bottom-0 top-0 flex items-center md:px-6 xl:absolute xl:px-0 ltr:left-0 ltr:right-0 rtl:left-0 rtl:right-0">
        <div className="relative z-10 flex w-full justify-center gap-x-16 px-8 xl:w-1/2 3xl:px-0">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            viewport={{ once: true }}
            className="flex flex-row gap-x-36">
            <div className="flex flex-col gap-y-12 lg:max-w-100 xl:max-w-141">
              <div className="flex flex-col gap-6 md:gap-y-8">
                <div className="flex flex-col gap-2 md:gap-y-6">
                  {title && <h2 className="!leading-semi-medium">{title}</h2>}
                </div>
                {description && <p className="max-w-131.5"> {description}</p>}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      {_.isEmpty(images) || (
        <Head>
          <link fetchPriority="high" rel="preload" as="image" href={images.url} />
        </Head>
      )}
      <div className="z-10 hidden w-1/2 md:mb-4 lg:block ltr:ml-auto ltr:mr-0 rtl:ml-0 rtl:mr-auto">
        {_.isEmpty(images) || (
          <Image
            src={images.url}
            placeholder="blur"
            blurDataURL={`${images.url}?width=33`}
            quality={100}
            alt="slider image"
            fill
            className="!relative h-full max-h-158 w-full object-cover ltr:rounded-bl-md ltr:rounded-tl-md rtl:rounded-br-md rtl:rounded-tr-md"
          />
        )}
      </div>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.4 }}
        viewport={{ once: true }}
        className="z-10 ml-auto mr-0 w-full gap-4 px-8 pt-10 lg:hidden">
        {_.isEmpty(images) || (
          <div className="flex w-full flex-col gap-y-4 no-underline">
            <Image
              src={images.url}
              placeholder="blur"
              blurDataURL={`${images.url}?width=33`}
              quality={100}
              alt="slider image"
              fill
              className="rounded-4 !relative h-full max-h-50 w-full rounded-sm object-cover"
            />
          </div>
        )}
      </motion.div>
    </section>
  );
}
