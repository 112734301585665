import React from 'react';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import _ from 'lodash';
import Image from 'next/image';

export default function IbisOneCentralDesktop({ data,replaceUrl }) {
  const title = _.get(data, 'title', null);
  const subtitle = _.get(data, 'subtitle', null);
  const descriptionContent = _.get(data, 'descriptionContent', null);
  const link = _.get(data, 'link', null);
  const imageFirst = _.get(data, 'imageFirst[0]', {});
  const imageSecond = _.get(data, 'imageSecond[0]', {});
  const imageThird = _.get(data, 'imageThird[0]', {});
  const imageFour = _.get(data, 'imageFour[0]', {});

  return (
    <div className="mx-auto hidden max-w-408 flex-row items-center gap-x-10 px-8 lg:flex xl:gap-x-14 3xl:gap-x-24 3xl:px-0">
      <div className="relative max-w-115">
        {_.isEmpty(imageFirst) || (
          <Image
            src={imageFirst?.content?.image?.url}
            alt={title}
            fill
            className="!relative !h-115 !w-full object-cover rounded"
          />
        )}
        {_.isEmpty(imageSecond) || (
          <Image
            src={imageSecond?.content?.image?.url}
            alt={title}
            fill
            className="!absolute !-bottom-20 !left-auto !top-auto rounded
            !h-40 !w-fit !-translate-x-1/2 object-cover 2xl:!-bottom-32 2xl:!h-60"
          />
        )}
      </div>
      <div className="flex flex-col items-center gap-y-14 pt-23 text-center max-w-[34.4%] 3xl:max-w-[32.4%]">
        <div className="flex flex-col gap-y-6">
          {title && <h2>{title}</h2>}
          {subtitle && <p className="text-clamp14to20">{subtitle}</p>}
        </div>
        {descriptionContent && <p>{descriptionContent}</p>}
        {_.isEmpty(link) || (
          <div className="w-full lg:w-fit">
            <Link
              href={replaceUrl(link.url)}
              target={link.target}
              className={cn(
                buttonVariants({ variant: 'secondary' }),
                '!lg:w-fit ibis-one-central w-full text-text-accent',
                link.target === '_blank' && 'external-link',
              )}>
              {link.name}
            </Link>
          </div>
        )}
      </div>
      <div className="relative max-w-124 pt-32 ltr:ml-14 rtl:mr-14">
        {_.isEmpty(imageFour) || (
          <Image
            src={imageFour?.content?.image?.url}
            alt={title}
            fill
            className="!relative !h-100 !w-full object-cover rounded"
          />
        )}
        {_.isEmpty(imageThird) || (
          <Image
            src={imageThird?.content?.image?.url}
            alt={title}
            fill
            className="!absolute !top-[15%] !h-60 !w-fit object-cover 
            ltr:!left-[-23%] rtl:!right-[-23%] rounded"
          />
        )}
      </div>
    </div>
  );
}
