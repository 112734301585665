import _ from 'lodash';
import React from 'react';

export default function HtmlContent({ data, id }) {
  const htmlContentText = _.get(data, 'htmlContentText', null);
  return (
    <section id={id} className="prose py-12 lg:py-30">
      <div className="relative mx-auto max-w-339 px-8 3xl:px-0">
        <div
          dangerouslySetInnerHTML={{ __html: htmlContentText }}
          className="flex flex-col gap-y-10 h4-div-child:!text-clamp20to32 html-content
          div-child:flex div-child:flex-col div-child:gap-y-6 div-ul-child:!m-0 
          div-ul-child:!-mt-4 div-li-child:text-clamp16to18 div-li-child:text-text-secondary 
          div-a-child:tracking-normal div-a-child:text-text-accent div-a-child:underline 
          div-p-child:text-clamp14to18 div-li-child:rtl:font-sans-ar"
        />
      </div>
    </section>
  );
}
