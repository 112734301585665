import React from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import useCustomCursor from '@/hooks/use-custom-cursor';
import getUpcomingEvents from '@/helpers/getUpcomingEvents';
import { getEventDateRange, convertMonthToAr } from '@/helpers/formatDate';

export default function DwtcEventsDesktop({ data, specificData, dir }) {
  const dwtcEvents = _.get(specificData, 'dwtcEvents', []);
  const upcomingEvents = _.slice(getUpcomingEvents(dwtcEvents), 0, 21);
  const { toggleCursor } = useCustomCursor();

  const breakpoints = {
    0: {
      slidesOffsetBefore: 32,
      slidesOffsetAfter: 32,
      spaceBetween: 14,
    },
    1024: {
      spaceBetween: 24,
      slidesOffsetBefore: 140,
      slidesOffsetAfter: 140,
    },
    1920: {
      spaceBetween: 24,
      slidesOffsetBefore: 140,
      slidesOffsetAfter: 140,
    },
  };

  return (
    <div className="hidden md:block">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.3 }}
        viewport={{ once: true }}
        className="pt-6 md:pt-10">
        <Swiper slidesPerView="auto" spaceBetween={24} breakpoints={breakpoints}>
          {_.map(upcomingEvents, (item, index) => {
            // console.log(item);
            const imageUrl =
              _.get(item, 'heroImage.url', null) || _.get(item, 'featuredImage.url', '');
            const itemTitle = _.get(item, 'title', null);

            return (
              <SwiperSlide
                key={index}
                className="max-w-116 2xl:max-w-128 3xl:max-w-132"
                onMouseEnter={() => toggleCursor(true)}
                onMouseLeave={() => toggleCursor(false)}>
                <a
                  href={`https://www.dwtc.com${item.url}`}
                  target="_blank"
                  rel="noreferrer"
                  className="!flex !h-full !grow !flex-col">
                  <div className="aspect-66by49 relative overflow-hidden rounded-t">
                    <Image src={imageUrl} alt={itemTitle} fill className="!object-cover" />
                  </div>
                  <div className="-mt-px flex h-25 flex-col justify-center gap-4 rounded-b bg-text-primary px-6 lg:h-41 lg:px-8">
                    <span className="text-clamp12to18 font-medium uppercase text-text-accent">
                      {dir === 'rtl'
                        ? convertMonthToAr(getEventDateRange(item))
                        : getEventDateRange(item)}
                    </span>
                    <div className="flex items-end justify-between gap-6 lg:gap-10">
                      <p className="text-clamp18to24 text-text-invert">{itemTitle}</p>
                      <i className="one-central-arrow-small -translate-y-2 !rotate-90 text-[9px] text-text-invert rtl:scale-[-1]" />
                    </div>
                  </div>
                </a>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </motion.div>
    </div>
  );
}
