import React, { useContext, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import Image from 'next/image';
import getTranslationByKey from '@/helpers/getTranslationByKey';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import Context from '@/context';

function DesktopMap({ data, items, MAP }) {
  const { translations, lang } = useContext(Context);
  const description = _.get(data, 'descriptionText', null);
  const link = _.get(data, 'link', null);
  const [currLocation, setCurrLocation] = useState(null);

  const onMapLoad = () => {
    const svgEl = document.querySelector('#location-map');
    const pins = document.querySelectorAll('[data-id="poi"]');

    _.forEach(pins, (pin) => {
      _.set(pin, 'style.cursor', 'pointer');
      const { nextSibling } = pin;
      _.set(nextSibling, 'style.cursor', 'pointer');

      pin.addEventListener('click', (event) => {
        const name = pin.getAttribute('data-name');
        const boundingClientRect = pin.getBoundingClientRect();
        const item = _.find(items, (val) => _.isEqual(_.kebabCase(val.title), _.kebabCase(name)));
        // console.log(item);

        if (!_.isEmpty(item)) {
          setCurrLocation({
            item,
            position: { top: boundingClientRect.top, left: boundingClientRect.left },
          });
        }
        event.stopPropagation();
      });

      nextSibling.addEventListener('click', (event) => {
        pin.dispatchEvent(new Event('click', { bubbles: true }));
        event.stopPropagation();
      });
    });

    if (svgEl) {
      svgEl.addEventListener('click', (event) => {
        event.preventDefault();
        setCurrLocation(null);
      });
    }
  };

  useEffect(() => {
    onMapLoad();
  }, []);

  return (
    <div className="prose hidden w-full overflow-hidden lg:block relative">
      <div className='relative'>
        <div className='bg-gradient-to-b from-transparent to-white 
        h-[200px] absolute z-40 pointer-events-none bottom-0 w-full' />
        <div id="compass" className="absolute top-[1px] ltr:left-[52px] rtl:right-12">
          <Image src="/images/compass.svg" alt="Compass icon" width={79} height={70} />
        </div>
        <MAP id="location-map" className="!h-auto !w-full" />
        <AnimatePresence>
          {_.isEmpty(currLocation) ||
            (() => {
              const itemImageUrl = _.get(currLocation, 'item.featuredImage.url', null);
              const itemTitle = _.trim(_.get(currLocation, 'item.title', null));
              const itemDistance = _.get(currLocation, 'item.distance', null);
              const itemCTA = _.get(currLocation, 'item.cta', null);
              return (
                <motion.div
                  key={currLocation.item.title}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="absolute z-[9999] h-50 w-45 
                  -translate-x-[calc(50%-0.75vw)] -translate-y-[348%] 
                  rounded-lg border-2 border-text-accent bg-white p-2 shadow-lg"
                  style={{
                    top: `${currLocation.position.top + window.scrollY}px`,
                    left: `${currLocation.position.left}px`,
                  }}>
                  <div className="relative flex h-full w-full flex-col items-center">
                    {itemImageUrl && (
                      <Image 
                      src={`${itemImageUrl}?width=328&height=200&mode=crop`} 
                      alt={itemTitle} 
                      width={164} 
                      height={100} 
                      priority 
                      placeholder="blur" 
                      blurDataURL={`${itemImageUrl}?width=32&height=20&mode=crop`}
                      />
                    )}
                    {itemTitle && (
                      <h2 className="mt-3 text-[14px] text-center uppercase tracking-wider 
                      text-text-accent w-full overflow-hidden text-ellipsis whitespace-nowrap">
                        {getTranslationByKey(itemTitle, translations, lang)}
                      </h2>
                    )}
                    {itemDistance && (
                      <span className="text-[11px] uppercase">
                        {`(${itemDistance} ${getTranslationByKey('mins', translations, lang)})`}
                      </span>
                    )}
                    {itemCTA && (
                      <a
                        href={itemCTA.url}
                        target={itemCTA.target}
                        className="mt-2 text-[11px] uppercase text-text-accent underline"
                        rel="noreferrer">
                        {getTranslationByKey(itemCTA.name, translations, lang)}
                      </a>
                    )}
                    <i className="absolute -bottom-[18px] left-1/2 h-4 w-4 -translate-x-1/2 
                    rotate-45 border-b-2 border-r-2 border-text-accent bg-white" />
                  </div>
                </motion.div>
              );
            })()}
        </AnimatePresence>
      </div>
      <div className="relative z-10 mx-auto flex max-w-339 flex-col 
      items-center gap-y-6 px-8 lg:gap-y-10 3xl:px-0">
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className="flex flex-col gap-y-5 text-center p-child:text-clamp16to24"
        />
        {_.isEmpty(link) || (
          <Link
            href={link.url}
            target={link.target}
            className={cn(
              buttonVariants({ variant: 'primary' }),
              'w-fit',
              '!py-4',
              '!px-3',
              '!font-extralight',
              link.target === '_blank' && 'external-link-white-2',
            )}>
            {getTranslationByKey(link.name, translations, lang)}
          </Link>
        )}
      </div>
    </div>
  );
}

export default DesktopMap;
