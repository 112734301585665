import React from 'react';
import _ from 'lodash';
import EventsTwentyFiveHoursDesktop from './EventsTwentyFiveHoursDesktop';
import EventsTwentyFiveHoursMobile from './EventsTwentyFiveHoursMobile';

export default function EventsTwentyFiveHours({ data, id }) {
  const title = _.get(data, 'title', null);
  return (
    <section id={id} className="prose bg-background-brand-5 pb-12 pt-12 lg:pt-54 lg:pb-30">
      <div className="px-8 text-center 3xl:px-0">{title && <h2>{title}</h2>}</div>
      <EventsTwentyFiveHoursDesktop data={data} />
      <EventsTwentyFiveHoursMobile data={data} />
    </section>
  );
}
