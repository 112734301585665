'use client'

import _ from 'lodash';
import { motion, AnimatePresence } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import React, { useState, useEffect } from 'react';
import Icons from '../layouts/Icons';

export default function HeaderMobileMenu({
  data,
  bottomLinks,
  instagram,
  footerBackgroundImage,
  path,
  parentPath,
  lang
}) {
  const [openIndex, setOpenIndex] = useState(null);
  useEffect(() => {
    const indexToOpen = _.findIndex(data, (item) => {
      const linkUrl = _.get(item, 'content.link.url', '#0');
      return linkUrl === parentPath;
    });
    if (indexToOpen !== -1) {
      setOpenIndex(indexToOpen);
    }
  }, [data, parentPath]);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="prose fixed inset-0 h-screen w-full bg-background-primary pt-36">
        <div className="flex h-[65vh] flex-col gap-y-8 overflow-auto px-8 pb-10">
          {!_.isEmpty(data) && (
            <div className="flex flex-col gap-4">
              {_.map(data, (item, index) => {
                const linkLabel = _.get(item, 'content.link.name', null);
                const linkChildren = _.get(item, 'content.itemChildren', []);
                const linkUrl = _.get(item, 'content.link.url', '#0');
                const linkTarget = _.get(item, 'content.link.target', '_self');

                return (
                  <div key={index} className="flex flex-col gap-5">
                    {linkLabel && (
                      <div className="flex items-center justify-between">
                        <Link
                          href={replaceUrl(linkUrl)}
                          target={linkTarget}
                          className={`header-mobile-menu ltr:text-left rtl:text-right ${openIndex === index ? 'text-text-accent' : ''} ${parentPath === replaceUrl(linkUrl) ? 'text-text-accent' : ''}`}>
                          {linkLabel}
                        </Link>
                        {!_.isEmpty(linkChildren) && (
                          <button
                            type="button"
                            onClick={() => handleToggle(index)}
                            className={`ml-2 ${openIndex === index ? 'text-text-accent' : ''}`}>
                            <Icons
                              icon="left-arrow"
                              className={`h-6 w-5 rotate-[270deg] svg-path:fill-black ${openIndex === index ? 'svg-path:fill-text-accent' : ''} ${parentPath === (linkUrl) ? 'svg-path:fill-text-accent' : ''}`}
                            />
                          </button>
                        )}
                      </div>
                    )}
                    {openIndex === index && (
                      <div className="flex flex-col gap-4 pb-4">
                        {_.map(linkChildren, (linkChildrenItem, linkChildrenItemIndex) => {
                          const linkChildrenItemUrl = _.get(
                            linkChildrenItem,
                            'content.link.url',
                            '#0',
                          );
                          const linkChildrenItemLabel = _.get(
                            linkChildrenItem,
                            'content.link.name',
                            null,
                          );
                          const linkChildrenItemTarget = _.get(
                            linkChildrenItem,
                            'content.link.target',
                            '_self',
                          );
                          return (
                            linkChildrenItemLabel && (
                              <Link
                                key={linkChildrenItemIndex}
                                href={replaceUrl(linkChildrenItemUrl)}
                                target={linkChildrenItemTarget}
                                className={`header-mobile-children-menu ${replaceUrl(linkChildrenItemUrl) === path ? 'text-text-accent' : ''}`}>
                                {linkChildrenItemLabel}
                              </Link>
                            )
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {!_.isEmpty(bottomLinks) && (
            <div className="flex flex-col gap-3">
              {_.map(bottomLinks, (bottomLinksItem, bottomLinksItemIndex) => {
                const bottomLinksItemUrl = _.get(bottomLinksItem, 'url', '#0');
                const bottomLinksItemLabel = _.get(bottomLinksItem, 'name', null);
                const bottomLinksItemtarget = _.get(bottomLinksItem, 'name', 'self');
                return (
                  bottomLinksItemLabel && (
                    <Link
                      key={bottomLinksItemIndex}
                      href={replaceUrl(bottomLinksItemUrl)}
                      className={`header-mobile-children-menu ${replaceUrl(bottomLinksItemUrl) === path ? 'text-text-accent' : ''}`}>
                      {bottomLinksItemLabel}
                    </Link>
                  )
                );
              })}
            </div>
          )}
          {_.isEmpty(instagram) || (
            <Link href={instagram} className="pt-4" target="_blank">
              <i className="one-central-instagram text-[28px] text-secondary" />
            </Link>
          )}
        </div>
        <div className="absolute bottom-0">
          {_.isEmpty(footerBackgroundImage) || (
            <Image
              src={footerBackgroundImage.url}
              alt="footer-image"
              fill
              className="!relative h-full w-full"
            />
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
