import React from 'react';
import _ from 'lodash';

export default function TwoColumnTextContentWithVideo({ data, id }) {
  const subtitle = _.get(data, 'subtitle', null);
  const title = _.get(data, 'title', null);
  const descriptionText = _.get(data, 'descriptionText', null);
  const desktopVideo = _.get(data, 'desktopVideo', null);
  const mobileVideo = _.get(data, 'mobileVideo', null);
  const desktopVideoPoster = _.get(data, 'desktopVideoPoster.url', null);
  const mobileVideoPoster = _.get(data, 'mobileVideoPoster.url', null);
  return (
    <section id={id} className="prose relative py-12 md:py-30">
      <div className="flex flex-col items-center gap-y-10 lg:flex-row lg:gap-x-12 
      lg:gap-y-0 xl:gap-x-20 2xl:gap-x-48 3xl:gap-x-72">
        <div className="px-8 lg:rtl:mr-auto lg:ltr:ml-auto lg:max-w-408 3xl:px-0">
          <div className="flex lg:max-w-132 flex-col gap-5 lg:gap-8">
            <div className="flex flex-col gap-3 lg:gap-y-6">
              {subtitle && <span className="body-1-variant">{subtitle}</span>}
              {title && <h2 className="ltr:w-4/5">{title}</h2>}
            </div>
            {descriptionText && (
              <div
                dangerouslySetInnerHTML={{ __html: descriptionText }}
                className="flex flex-col gap-6"
              />
            )}
          </div>
        </div>
        {desktopVideo && (
          <div className="hidden lg:block lg:w-1/2">
            <video
              muted
              autoPlay
              playsInline
              loop
              preload="metadata"
              poster={`${desktopVideoPoster}?width=400`}
              crossOrigin="anonymous"
              className="h-175 w-full object-cover ltr:lg:rounded-l-md 
              ltr:lg:rounded-r-none rtl:lg:rounded-l-none rtl:lg:rounded-r-md"
              >
              <source src={`/api/proxy-video?videoPath=${desktopVideo}`} type="video/mp4" />
          </video>
          </div>
        )}
        {mobileVideo && (
          <div className="block px-8 w-full lg:hidden">
            <video
              muted
              autoPlay
              playsInline
              loop
              preload="metadata"
              poster={mobileVideoPoster}
              crossOrigin="anonymous"
              className="h-62 w-full object-cover"
            >
              <source src={`/api/proxy-video?videoPath=${mobileVideo}`} type="video/mp4" />
          </video>
          </div>
        )}
      </div>
    </section>
  );
}
