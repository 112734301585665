import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
  `rounded-sm uppercase py-4 xl:py-5 px-3 md:px-4 lg:px-6 2xl:px-8 transition-all duration-300 no-underline
  flex justify-center items-center text-center`,
  {
    variants: {
      variant: {
        primary:
          `bg-text-accent border-[1.5px] border-text-accent text-white lg:hover:text-white lg:hover:bg-background-brand-7
          md:hover:border-background-brand-7`,
        secondary:
          `bg-white border-[1.5px] border-text-accent text-text-accent lg:hover:text-white lg:hover:bg-text-accent`,
        external:
          `bg-white border-[1.5px] border-text-accent text-text-accent lg:hover:text-white lg:hover:bg-text-accent`,
      },
      size: {
        default: '',
        sm: '',
        lg: '',
        icon: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

const Button = React.forwardRef(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';

export { Button, buttonVariants };
