import _ from 'lodash';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/grid';
import { Grid, Autoplay } from 'swiper/modules';
import Link from 'next/link';
import Image from 'next/image';

export default function OurTenants({ data, id }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const title = _.get(data, 'title', null);
  const items = _.get(data, 'items', []);
  const slidesPerView = 4;

  const breakpoints = {
    0: {
      slidesPerView: 2,
      grid: {
        fill: 'row',
        rows: 3,
      },
      spaceBetween: 16,
    },
    640: {
      slidesPerView: 4,
      spaceBetween: 24,
      grid: {
        fill: 'row',
        rows: 2,
      },
    },
  };

  const totalLength = Math.ceil(items.length / slidesPerView);
  const activeGroupIndex = Math.floor(activeIndex / slidesPerView);
  const percentageWidth = ((activeIndex + 1) / totalLength) * 100;

  return (
    <section id={id} className="prose bg-background-primary py-12 lg:py-30">
      <div className="relative mx-auto flex max-w-408 flex-col gap-y-10 px-8 text-center md:gap-y-16 3xl:px-0">
        {title && <h2>{title}</h2>}
        {!_.isEmpty(items) && (
          <div>
            <Swiper
              breakpoints={breakpoints}
              modules={[Grid, Autoplay]}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              speed={800}
              className="mySwiper"
              onBreakpoint={breakpoints}
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}>
              {_.map(items, (item, index) => {
                const itemLink = _.get(item, 'content.link.url', '#0');
                const itemTarget = _.get(item, 'content.link.target', '_self');
                const itemLogo = _.get(item, 'content.tenantsLogo', {});
                return (
                  itemLogo && (
                    <SwiperSlide key={index}>
                      <Link
                        href={itemLink}
                        className={`!flex !min-h-32 items-center justify-center border border-background-tertiary bg-white py-5 md:!h-42 md:!min-h-max ${index === activeIndex ? 'active' : ''}`}
                        target={itemTarget}>
                        <Image
                          src={itemLogo.url}
                          alt="Our Tenants"
                          fill
                          className="!relative !object-contain"
                        />
                      </Link>
                    </SwiperSlide>
                  )
                );
              })}
            </Swiper>
            {_.size(items) > 1 && (
              <div className="max-w-560 body-1-variant flex flex-row items-center justify-center gap-4 pt-8 !text-text-primary md:pt-20 rtl:!text-clamp12to18 rtl:!font-normal">
                {_.map(_.range(totalLength), (indicatorIndex) => (
                  <div
                    key={indicatorIndex}
                    className={`h-1 w-6 ${
                      activeIndex === indicatorIndex ? 'bg-text-accent' : 'bg-background-tertiary'
                    }`}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
}
