'use client';

import React from 'react';
import _ from 'lodash';

import CertificationRecognitionItem from './CertificationRecognitionItem';

export default function CertificationRecognition({ data, id }) {
  const title = _.get(data, 'title', null);
  const items = _.get(data, 'items', null);

  return (
    <section
      id={id}
      className="bg-base prose relative flex w-full flex-col gap-y-10 px-8 py-12 lg:gap-y-20 lg:px-48 lg:py-30">
      <div className="mx-auto w-full px-8 3xl:px-16 lg:max-w-408 3xl:max-w-408 justify-center">
        {_.isEmpty(title) || <h2 className="text-center">{title}</h2>}
      </div>

      <div className="flex flex-wrap gap-8 md:gap-16 lg:justify-center lg:max-w-408 3xl:max-w-408 mx-auto w-full">
        {_.isEmpty(items) ||
          _.map(items, (item, index) => (
            <CertificationRecognitionItem key={index} data={item} border={index === 0} />
          ))}
      </div>
    </section>
  );
}
