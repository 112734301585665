import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import _ from 'lodash';
import Context from '@/context';

export default function ImageWithOverlayCard({ data, id }) {
  const title = _.get(data, 'title', null);
  const cardText = _.get(data, 'cardText', null);
  const link = _.get(data, 'link', null);
  const images = _.get(data, 'images[0]', null);
  const boxedLayout = _.get(data, 'boxedLayout', false);
  const bgColor = _.get(data, 'bgColor', []);
  const background = bgColor ? `bg-${bgColor.label}` : 'bg-background-brand-2';
  const { lang } = useContext(Context);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);

  return (
    <section
      id={id}
      className={`prose relative flex flex-col items-center overflow-hidden bg-background-base lg:flex-row ${boxedLayout ? 'lg:justify-center lg:px-36 lg:pb-30 lg:pt-35' : ''}`}>
      {_.isEmpty(images) || (
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          viewport={{ once: true }}
          className={`hidden w-full md:block lg:w-[71%] ${boxedLayout ? 'lg:max-w-201' : ''}`}>
          <Image
            src={images?.content?.image?.url}
            alt={title}
            fill
            className="!relative ltr:lg:rounded-l-none ltr:lg:rounded-r-md rtl:lg:rounded-l-md rtl:lg:rounded-r-none"
          />
        </motion.div>
      )}
      {_.isEmpty(images) || (
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          viewport={{ once: true }}
          className="block w-full md:hidden">
          <Image
            src={images?.content?.mobileImage?.url}
            alt={title}
            fill
            className="!relative max-h-69 ltr:lg:rounded-l-none ltr:lg:rounded-r-md rtl:lg:rounded-l-md rtl:lg:rounded-r-none object-cover"
          />
        </motion.div>
      )}
      <div
        className={`lg:w-143 flex w-full flex-col justify-center gap-y-10 md:gap-y-12 lg:h-96 xl:h-113 2xl:w-193 3xl:h-158 3xl:w-203 
          ${background} z-10 px-8 pb-14 pt-8 md:px-12 md:py-10 xl:px-22 3xl:px-24 
        ${boxedLayout ? 'items-center 3xl:h-203 3xl:w-207' : '2xl:ltr:-ml-96 2xl:rtl:-mr-96 lg:ltr:-ml-40 lg:rtl:-mr-40'}
        `}>
        <div
          className={`${boxedLayout ? 'max-w-132' : 'max-w-109'} flex flex-col gap-y-3 md:gap-y-8`}>
          {title && <h2 className="rtl:max-w-120">{title}</h2>}
          {cardText && (
            <div
              className="max-w-128 ltr:lg:text-left rtl:lg:text-right"
              dangerouslySetInnerHTML={{ __html: cardText }}
            />
          )}
        </div>
        {_.isEmpty(link) || (
          <div className="w-fit">
            <Link
              href={replaceUrl(link.url)}
              target={link.target}
              className={cn(
                buttonVariants({ variant: 'secondary' }),
                '!lg:w-fit image-with-overlay-card w-full text-text-accent',
                link.target === '_blank' && 'external-link',
              )}>
              {link.name}
            </Link>
          </div>
        )}
      </div>
    </section>
  );
}
