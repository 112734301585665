import React, { useContext } from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import Link from 'next/link';
import Image from 'next/image';
import BoxedContainer from '@/components/layouts/BoxedContainer';
import { buttonVariants } from '@/components/ui/button';
import Context from '@/context';

export default function TextContentWithTwoCards({ data }) {
  const subtitle = _.get(data, 'subtitle', null);
  const title = _.get(data, 'title', null);
  const items = _.get(data, 'items', []);
  const { lang } = useContext(Context);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);
  if (_.isEmpty(items)) return null;
  return (
    <section id="text-content-with-two-cards" className="prose bg-background-base py-12 lg:py-30">
      <BoxedContainer>
        <div className="flex flex-col gap-y-8 lg:gap-y-16">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            viewport={{ once: true }}
            className="flex w-full flex-col items-center justify-center gap-y-2 md:gap-y-6">
            {subtitle && <span className="body-1-variant">{subtitle}</span>}
            {title && <h2 className="text-center">{title}</h2>}
          </motion.div>
          {_.isEmpty(items) || (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.4 }}
              viewport={{ once: true }}
              className="flex flex-col gap-8 md:flex-row md:gap-6 lg:pt-1.5 cards">
              {_.map(items, (item, index) => {
                const cardTitle = _.get(item, 'content.title', null);
                const cardText = _.get(item, 'content.htmlText', null);
                const cardLink = _.get(item, 'content.link', '#0');
                const cardImage = _.get(item, 'content.image', {});
                return (
                  <div
                    key={index}
                    className="flex flex-col justify-between rounded-t-md bg-background-brand-3 md:w-1/2">
                    <div className="px-5 py-8 md:px-14 md:py-16">
                      <div className="mx-auto flex max-w-155 flex-col items-center gap-5">
                        <div className="flex flex-col gap-y-2 text-center md:gap-y-5">
                          {cardTitle && (
                            <h3 className="!tracking-normal lg:!tracking-tight ltr:!font-normal rtl:!font-bold">
                              {cardTitle}
                            </h3>
                          )}
                          {cardText && (
                            <div
                              className="p-child:ltr:text-clamp14to20 p-child:rtl:!text-clamp20to24"
                              dangerouslySetInnerHTML={{ __html: cardText }}
                            />
                          )}
                        </div>
                        {_.isEmpty(cardLink) || (
                          <Link
                            href={replaceUrl(cardLink.url)}
                            target={cardLink.target}
                            aria-label={cardTitle}
                            className={`${buttonVariants({ variant: 'secondary' })} two-cards-link w-fit overflow-hidden`}>
                            {cardLink.name} <span className='opacity-0 left-full absolute'>about {cardTitle}</span>
                          </Link>
                        )}
                      </div>
                    </div>
                    {_.isEmpty(cardImage) || (
                      <div>
                        <Image
                          src={`${cardImage.url}?width=884`}
                          placeholder="blur"
                          blurDataURL={`${cardImage.url}?width=88`}
                          quality={100}
                          alt={cardTitle}
                          fill
                          className="!relative !h-50 w-full rounded-b-md object-cover lg:!h-136"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </motion.div>
          )}
        </div>
      </BoxedContainer>
    </section>
  );
}
