import React, { useContext } from 'react';
import _ from 'lodash';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { buttonVariants } from '@/components/ui/button';
import Image from 'next/image';
import Context from '@/context';
import Head from 'next/head';

export default function LeftImageWithTextContentCTA({ data }) {
  const title = _.get(data, 'title', null);
  const text = _.get(data, 'htmlText', null);
  const image = _.get(data, 'image', {});
  const videoUrl = _.get(data, 'videoUrl', null);
  const link = _.get(data, 'link', null);
  const { lang } = useContext(Context);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);

  return (
    <section id="left-image-with-text-content-cta" className="overflow-hidden">
      <div className="flex flex-col lg:flex-row">
        <div className="relative w-full bg-background-primary px-8 before:absolute before:bottom-0 before:left-0 before:right-0 before:!block before:h-1/2 before:w-full before:bg-background-brand-2 lg:w-[35%] lg:bg-transparent lg:px-0 lg:py-30 before:lg:!hidden">
          {_.isEmpty(image) || (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.3 }}
              viewport={{ once: true }}
              className="relative z-10 lg:-ml-40 lg:-mr-40 2xl:-ml-64 2xl:-mr-64">
              {!_.isEmpty(videoUrl) ? (
                <>
                  <Head>
                    <link fetchPriority="high" rel="preload" as="image" href={image.url} />
                  </Head>
                  <video
                    muted
                    autoPlay
                    playsInline
                    loop
                    preload="metadata"
                    poster={image.url}
                    crossOrigin="anonymous"
                    className="!relative hidden max-h-60 w-full rounded-md object-cover sm:max-h-100 md:block lg:max-h-152.5 ltr:lg:rounded-l-none ltr:lg:rounded-r-md rtl:lg:rounded-l-md rtl:lg:rounded-r-none">
                    <source src={`/api/proxy-video?videoPath=${videoUrl}`} type="video/mp4" />
                  </video>
                  <video
                    muted
                    autoPlay
                    playsInline
                    loop
                    preload="metadata"
                    poster={image.url}
                    crossOrigin="anonymous"
                    className="!relative max-h-60 w-full rounded-md object-cover sm:max-h-100 md:hidden lg:max-h-152.5 ltr:lg:rounded-l-none ltr:lg:rounded-r-md rtl:lg:rounded-l-md rtl:lg:rounded-r-none">
                    <source src={`/api/proxy-video?videoPath=${videoUrl}`} type="video/mp4" />
                  </video>
                </>
              ) : (
                <Image
                  src={image.url}
                  alt={title}
                  fill
                  className="!relative max-h-60 w-full rounded-md object-cover sm:max-h-100 lg:max-h-152.5 ltr:lg:rounded-l-none ltr:lg:rounded-r-md rtl:lg:rounded-l-md rtl:lg:rounded-r-none"
                />
              )}
            </motion.div>
          )}
        </div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
          viewport={{ once: true }}
          className="prose flex w-full justify-center bg-background-brand-2 pb-10 pl-8 pr-8 pt-8 lg:w-[65%] lg:justify-start lg:py-0 lg:ltr:pl-48 lg:ltr:pr-20 xl:ltr:pl-60 2xl:ltr:pl-103 2xl:ltr:pr-36 lg:rtl:pl-20 lg:rtl:pr-48 xl:rtl:pr-60 2xl:rtl:pl-36 2xl:rtl:pr-103">
          <div className="flex h-full max-w-132 flex-col justify-center gap-y-8 lg:gap-y-14">
            <div className="flex flex-col gap-y-4 lg:gap-y-6">
              {title && (
                <span className="body-1-variant text-center ltr:lg:text-left rtl:lg:text-right">
                  {title}
                </span>
              )}

              {text && (
                <div
                  className="text-center ltr:lg:text-left rtl:lg:text-right"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              )}
            </div>
            {_.isEmpty(link) || (
              <div className="w-full lg:w-fit">
                <Link
                  href={replaceUrl(link.url)}
                  target={link.target}
                  className={`${buttonVariants({ variant: 'secondary' })} !lg:w-fit left-image-with-text-content w-full`}>
                  {link.name}
                </Link>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </section>
  );
}
