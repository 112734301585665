import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import useCustomCursor from '@/hooks/use-custom-cursor';
import BoxedContainer from '@/components/layouts/BoxedContainer';
import Link from 'next/link';

function InstaFeed({ id, data }) {
  const feedId = _.get(data, 'feedId', '');
  const title = _.get(data, 'title', '');
  const text = _.get(data, 'text', '');
  const link = _.get(data, 'link', null);
  const [feedData, setFeedData] = useState([]);
  const { toggleCursor } = useCustomCursor();

  const fetchFeed = async () => {
    try {
      const response = await fetch(`/api/insta?id=${feedId}`);
      const res = await response.json();
      const posts = _.get(res, 'posts', []);
      posts.sort((valueA, valueB) => {
        const dateA = _.get(valueA, 'source_created_at', '');
        const dateB = _.get(valueB, 'source_created_at', '');
        return new Date(dateB) - new Date(dateB);
      });
      setFeedData(_.slice(posts, 0, 30));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (feedId) {
      fetchFeed();
    }
  }, [feedId]);

  // console.log(feedData);

  const breakpoints = {
    0: {
      slidesOffsetBefore: 32,
      slidesOffsetAfter: 32,
      spaceBetween: 14,
    },
    1280: {
      spaceBetween: 24,
      slidesOffsetBefore: 140,
      slidesOffsetAfter: 140,
    },
    1920: {
      spaceBetween: 24,
      slidesOffsetBefore: 140,
      slidesOffsetAfter: 140,
    },
  };

  return (
    <section id={id} className="prose py-14 lg:py-30">
      <BoxedContainer>
        <div className="flex flex-col gap-6 text-center">
          <h2 className="!font-normal" dir="ltr">
            {title}
          </h2>
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className="text-clamp14to18 a-child:underline p-a-child:text-clamp14to18 p-a-child:!font-normal p-a-child:!tracking-normal
            p-a-child:hover:text-text-accent rtl:font-sans-ar"
          />
        </div>
      </BoxedContainer>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.3 }}
        viewport={{ once: true }}
        className="pt-6 md:pt-10">
        <Swiper slidesPerView="auto" spaceBetween={24} autoHeight breakpoints={breakpoints}>
          {_.map(feedData, (item, index) => {
            // console.log(item);
            const image = _.get(item, 'image.url', null) || _.get(item, 'image_large');
            const itemTitle = _.get(item, 'content.title', null);
            const itemText = _.get(item, 'text', null);
            const cta = _.get(item, 'content.cta', null);

            return (
              <SwiperSlide
                key={index}
                className="!max-w-60 md:!max-w-72 lg:!max-w-85 2xl:!max-w-97.5"
                onMouseEnter={() => toggleCursor(true)}
                onMouseLeave={() => toggleCursor(false)}>
                <div className="!flex !h-full !grow !flex-col gap-4">
                  <div className="relative aspect-square overflow-hidden rounded">
                    <Image src={image} alt={itemTitle} fill className="!object-cover" />
                  </div>
                  <div className="flex h-full grow gap-5">
                    <p className="line-clamp-3">{itemText}</p>
                    <i className="one-central-instagram text-[24px] text-text-accent" />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </motion.div>
    </section>
  );
}

export default InstaFeed;
