'use client';

import React from 'react';
import _ from 'lodash';

import LocationShowcaseItem from './LocationShowcaseItem';

export default function LocationShowcase({ data, id }) {
  const items = _.get(data, 'itemsContent', null);

  return (
    <section
      id={id}
      className="prose relative w-full gap-y-20 bg-background-base px-8 pb-12 lg:pb-30">
      <div className="relative mx-auto flex max-w-92 flex-col gap-y-10 lg:gap-y-20 md:max-w-235.5 lg:max-w-408">
        {_.isEmpty(items) ||
          _.map(items, (item, index) => <LocationShowcaseItem key={index} data={item} />)}
      </div>
    </section>
  );
}
