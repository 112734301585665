import React from 'react';
import Link from 'next/link';
import { buttonVariants } from '@/components/ui/button';
import _ from 'lodash';
import { cn } from '@/lib/utils';
import Image from 'next/image';

export default function IbisOneCentralMobile({ data,replaceUrl }) {
  const title = _.get(data, 'title', null);
  const subtitle = _.get(data, 'subtitle', null);
  const descriptionContent = _.get(data, 'descriptionContent', null);
  const link = _.get(data, 'link', null);
  const imageFirst = _.get(data, 'imageFirst[0]', {});
  const imageSecond = _.get(data, 'imageSecond[0]', {});
  const imageThird = _.get(data, 'imageThird[0]', {});
  const imageFour = _.get(data, 'imageFour[0]', {});
  return (
    <div className="flex flex-col gap-10 lg:hidden">
      <div className="flex flex-row justify-between gap-8">
        <div className="relative z-10 w-1/2">
          {_.isEmpty(imageFirst) || (
            <Image
              src={imageFirst?.content?.mobileImage?.url}
              alt={title}
              fill
              className="!relative !h-60 !w-full object-cover rounded"
            />
          )}
          {_.isEmpty(imageSecond) || (
            <Image
              src={imageSecond?.content?.mobileImage?.url}
              alt={title}
              fill
              className="!absolute !top-[30%] !h-32 !w-fit object-cover ltr:!left-auto 
              ltr:!-right-20 rtl:!right-auto rtl:!-left-20 rounded"
            />
          )}
        </div>
        <div className="relative mt-10 w-1/2">
          {_.isEmpty(imageFour) || (
            <Image
              src={imageFour?.content?.mobileImage?.url}
              alt={title}
              fill
              className="!relative !h-56 !w-full object-cover"
            />
          )}
          {_.isEmpty(imageThird) || (
            <Image
              src={imageThird?.content?.mobileImage?.url}
              alt={title}
              fill
              className="!absolute !top-[-30%] !h-32 !w-32 object-cover 
              ltr:!right-0 ltr:!left-auto rtl:!left-0 rtl:!right-auto rounded"
            />
          )}
        </div>
      </div>
      <div className="flex flex-col items-center gap-y-6 px-8 text-center">
        <div className="flex flex-col gap-y-3">
          {title && <h2>{title}</h2>}
          {subtitle && <p className="text-clamp14to20">{subtitle}</p>}
        </div>
        {descriptionContent && <p>{descriptionContent}</p>}
        {_.isEmpty(link) || (
          <div className="w-fit">
            <Link
              href={replaceUrl(link.url)}
              target={link.target}
              className={cn(
                buttonVariants({ variant: 'secondary' }),
                'ibis-one-central w-full text-text-accent',
                link.target === '_blank' && 'external-link',
              )}>
              {link.name}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
