import React, { useState, useEffect, useRef } from 'react';
import getUpcomingEvents from '@/helpers/getUpcomingEvents';
import { getEventDateRange, convertMonthToAr } from '@/helpers/formatDate';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import _ from 'lodash';
import Image from 'next/image';

export default function DwtcEventsMobile({ specificData, dir }) {
  const dwtcEvents = _.get(specificData, 'dwtcEvents', []);
  const upcomingEvents = _.slice(getUpcomingEvents(dwtcEvents), 0, 20);
  if (_.isEmpty(upcomingEvents)) return null;

  const chunkedEvents = _.chunk(upcomingEvents, 4);
  const [activeIndex, setActiveIndex] = useState(1);
  const totalLength = chunkedEvents.length;
  const percentageWidth = (activeIndex / totalLength) * 100;

  // Reference to Swiper instance
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex + 1);
  };

  return (
    <div className="block px-8 pt-10 md:hidden">
      <Swiper
        onSlideChange={handleSlideChange}
        autoHeight
        onSwiper={(swiper) => (swiperRef.current = swiper)} // Store Swiper instance
      >
        {chunkedEvents.map((chunk, chunkIndex) => (
          <SwiperSlide key={chunkIndex} className="!cursor-default">
            <div className="flex flex-col gap-4">
              {chunk.map((item, index) => {
                const imageUrl =
                  _.get(item, 'heroImage.url', null) || _.get(item, 'featuredImage.url', '');
                const itemTitle = _.get(item, 'title', null);
                return (
                  <a
                    key={index}
                    href={`https://www.dwtc.com${item.url}`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-full">
                    <div className="relative min-h-44 overflow-hidden rounded-t">
                      <Image
                        src={imageUrl}
                        alt={itemTitle}
                        fill
                        className="!h-44 !object-cover md:!h-full"
                      />
                    </div>
                    <div className="flex min-h-25 flex-col justify-center gap-4 rounded-b bg-text-primary px-6 py-6">
                      <span className="text-clamp12to18 font-medium uppercase text-text-accent">
                        {dir === 'rtl'
                          ? convertMonthToAr(getEventDateRange(item))
                          : getEventDateRange(item)}
                      </span>
                      <div className="flex items-center justify-between gap-6 lg:gap-10">
                        <p className="text-clamp18to24 !tracking-normal text-text-invert">
                          {itemTitle}
                        </p>
                        <i className="one-central-arrow-small -translate-y-2 !rotate-90 text-[9px] text-text-invert rtl:scale-[-1]" />
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="flex flex-row items-center justify-center gap-4 pt-10">
        {_.map(chunkedEvents, (activeItem, activeItemIndex) => (
          <button
            key={activeItemIndex}
            type="button"
            onClick={() => {
              if (swiperRef.current) {
                swiperRef.current.slideTo(activeItemIndex);
              }
            }}
            className={`h-1 w-6 ${
              activeIndex === activeItemIndex + 1 ? 'bg-text-accent' : 'bg-background-tertiary'
            }`}
          />
        ))}
      </div>
    </div>
  );
}
