import React from 'react';
import Image from 'next/image';
import _ from 'lodash';
import Head from 'next/head';

export default function ResponsiveImage({ desktopImage, mobileImage, className, alt }) {
  return (
    <>
      <Head>
        <link rel="preload" as="image" href={mobileImage} />
        <link rel="preload" as="image" href={desktopImage} />
      </Head>
      {desktopImage && !_.isEmpty(desktopImage) && (
        <div className="hidden md:block responsive-img-wrapper">
          <Image
            src={`${desktopImage}?width=940`}
            placeholder="blur"
            blurDataURL={`${desktopImage}?width=94`}
            quality={100}
            alt={alt || 'One Central'}
            fill
            className={`!relative ${className}`}
          />
        </div>
      )}
      {mobileImage && !_.isEmpty(mobileImage) && (
        <div className="block md:hidden">
          <Image
            src={`${mobileImage}?width=320`}
            placeholder="blur"
            blurDataURL={`${mobileImage}?width=32`}
            quality={100}
            alt={alt || 'One Central'}
            fill
            className={`!relative ${className}`}
          />
        </div>
      )}
    </>
  );
}
