'use client'

import React, { useEffect, useState } from 'react';

export default function useScroll() {
  const windowGlobal = typeof window !== 'undefined' && window;
  const [position, setPosition] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const moving = windowGlobal.pageYOffset <= 50 ? 0 : windowGlobal.pageYOffset;
    setVisible(position > moving || moving === 0);
    setPosition(moving);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [position]);

  return { position, visible };
}