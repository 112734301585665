import React from 'react';

import Arrow from '../../assets/svg/arrow.svg';
import ArrowUp from '../../assets/svg/arrowup.svg';
import ArrowDown from '../../assets/svg/arrowdown.svg';
import BannerOverlay from '../../assets/svg/banner-overlay.svg';
import Facebook from '../../assets/svg/facebook.svg';
import FooterLogo from '../../assets/svg/footer-logo.svg';
import Instagram from '../../assets/svg/instagram.svg';
import Linkedin from '../../assets/svg/linkedin.svg';
import LiveChat from '../../assets/svg/live-chat.svg';
import Twitter from '../../assets/svg/twitter.svg';
import Logo from '../../assets/svg/logo.svg';
import Search from '../../assets/svg/search.svg';
import MenuOpen from '../../assets/svg/menu-open.svg';
import MenuClose from '../../assets/svg/menu-close.svg';
import Spining from '../../assets/svg/spining.svg';
import LeftArrow from '../../assets/svg/left-arrow.svg';
import RightArrow from '../../assets/svg/right-arrow.svg';
import Error from '../../assets/svg/error.svg';
import Play from '../../assets/svg/play-icon.svg';
import Pause from '../../assets/svg/pause-icon.svg';
import ZoomIn from '../../assets/svg/zoom-in.svg';
import ZoomOut from '../../assets/svg/zoom-out.svg';

export default function Icons({ icon, className }) {
  const components = [
    { comp: <Arrow className={className} />, icon: 'arrow' },
    { comp: <ArrowDown className={className} />, icon: 'arrowdown' },
    { comp: <ArrowUp className={className} />, icon: 'arrowup' },
    { comp: <BannerOverlay className={className} />, icon: 'banner-overlay' },
    { comp: <Facebook className={className} />, icon: 'facebook' },
    { comp: <FooterLogo className={className} />, icon: 'footer-logo' },
    { comp: <Instagram className={className} />, icon: 'instagram' },
    { comp: <Linkedin className={className} />, icon: 'linkedin' },
    { comp: <Logo className={className} />, icon: 'logo' },
    { comp: <LiveChat className={className} />, icon: 'live-chat' },
    { comp: <Twitter className={className} />, icon: 'twitter' },
    { comp: <Search className={className} />, icon: 'search' },
    { comp: <MenuOpen className={className} />, icon: 'menu-open' },
    { comp: <MenuClose className={className} />, icon: 'menu-close' },
    { comp: <Spining className={className} />, icon: 'spining' },
    { comp: <LeftArrow className={className} />, icon: 'left-arrow' },
    { comp: <RightArrow className={className} />, icon: 'right-arrow' },
    { comp: <Error className={className} />, icon: 'error' },
    { comp: <Play className={className} />, icon: 'play-icon' },
    { comp: <Pause className={className} />, icon: 'pause-icon' },
    { comp: <ZoomIn className={className} />, icon: 'zoom-in' },
    { comp: <ZoomOut className={className} />, icon: 'zoom-out' },
  ];

  return icon && components.some((el) => el.icon === icon)
    ? components.find((el) => el.icon === icon).comp
    : null;
}
