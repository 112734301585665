import React, { useState, useContext } from 'react';
import dynamic from 'next/dynamic';
import _ from 'lodash';
import { motion } from 'framer-motion';
import Link from 'next/link';
import Image from 'next/image';
import WordFader from '@/components/layouts/WordFader';
import getTranslationByKey from '@/helpers/getTranslationByKey';
import Context from '@/context';
import BoxedContainer from './layouts/BoxedContainer';
import Icons from './layouts/Icons';
import FooterAnimation from './Footer/FooterAnimation';

const BrandMessages = dynamic(() => import('./layouts/TextAnimation/BrandMessages'), {
  ssr: false,
  loading: () => '',
});

export default function Footer({ data, dir }) {
  const { translations, lang } = useContext(Context);
  const copyrightText = _.get(data, 'copyrightText', null);
  const footerMenu = _.get(data, 'footerMenu[0]', []);
  const bottomLinks = _.get(data, 'bottomLinks', []);
  // const footerBackgroundImage = _.get(data, 'footerBackgroundImage', null);
  const instagram = _.get(data, 'instagram', null);
  // const [isIframeVisible, setIsIframeVisible] = useState(false);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);
  const footerMessages = [
    {
      message: getTranslationByKey('BUSINESS', translations, lang),
    },
    {
      message: getTranslationByKey('LIFESTYLE', translations, lang),
    },
    {
      message: getTranslationByKey('COMMUNITY', translations, lang),
    },
  ];
  // const footerWords = [
  //   getTranslationByKey('BUSINESS', translations, lang),
  //   getTranslationByKey('LIFESTYLE', translations, lang),
  //   getTranslationByKey('COMMUNITY', translations, lang),
  // ];

  // const handleIconClick = () => {
  //   setIsIframeVisible(true);
  // };
  //
  // const handleCloseClick = () => {
  //   setIsIframeVisible(false);
  // };

  return (
    <footer id="footer" className="prose bg-background-primary pt-12 md:pt-28">
      <BoxedContainer>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          viewport={{ once: true }}
          className="flex flex-col items-center gap-10 lg:flex-row lg:items-start lg:justify-between">
          <Link href="#0" aria-label='Home Page'>
            <Icons icon="logo" className="h-18 w-16" />
          </Link>
          <div className="flex flex-col gap-7 md:gap-10">
            <div className="flex max-w-72 flex-col gap-4 md:max-w-full md:gap-8">
              <div className="flex flex-row flex-wrap items-center justify-center gap-4 md:gap-14 lg:pt-7">
                {_.isEmpty(footerMenu) ||
                  _.map(
                    footerMenu?.content?.urls,
                    (footerMenuItem, index) =>
                      _.isEmpty(footerMenuItem.url) || (
                        <Link
                          key={index}
                          href={replaceUrl(footerMenuItem.url)}
                          target={footerMenuItem.target}
                          className="footer-menu transition-all duration-150 hover:text-text-accent">
                          {footerMenuItem.name}
                        </Link>
                      ),
                  )}
              </div>
              {_.isEmpty(bottomLinks) || (
                <div className="flex flex-row items-center justify-center gap-8">
                  {_.map(
                    bottomLinks,
                    (bottomLinksItem, index1) =>
                      _.isEmpty(bottomLinksItem.url) || (
                        <Link
                          key={index1}
                          href={replaceUrl(bottomLinksItem.url)}
                          target={bottomLinksItem.target}
                          className="footer-bottom-menu transition-all duration-150 hover:text-text-accent">
                          {bottomLinksItem.name}
                        </Link>
                      ),
                  )}
                </div>
              )}
            </div>
            {_.isEmpty(instagram) || (
              <div className="flex flex-row justify-center gap-10">
                <Link href={instagram} className="" target="_blank">
                  <i className="one-central-instagram text-[24px] text-secondary hover:text-text-accent md:text-[32px]" />
                </Link>
              </div>
            )}
            {_.isEmpty(copyrightText) || (
              <p className="subtitle flex justify-center !font-normal text-text-secondary">
                {copyrightText.replace('{{YEAR}}', new Date().getFullYear())}
              </p>
            )}
          </div>
          <div className='"h-22 hidden w-22 md:block' />
        </motion.div>
      </BoxedContainer>
      <div className="prose mt-10 flex flex-col items-center gap-y-10 border-t border-t-background-tertiary pt-8 md:mt-20 md:gap-y-16 md:pt-16">
        <motion.h1
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
          viewport={{ once: true }}
          className="item-center flex w-full justify-center overflow-hidden whitespace-nowrap text-center uppercase rtl:w-63 rtl:lg:w-124 rtl:flex-row-reverse">
          <span className="ml-[12%] mr-2 flex items-center md:ml-0 lg:mr-4 rtl:mr-2 rtl:ml-[20%] rtl:md:ml-[13%] rtl:lg:mr-4">
            {getTranslationByKey('Always', translations, lang)}
          </span>
          <BrandMessages messagesWithImages={footerMessages} />
          {/* <WordFader words={footerWords} /> */}
        </motion.h1>
        {/* _.isEmpty(footerBackgroundImage) || (
          <Image
            src={footerBackgroundImage.url}
            alt="footer-image"
            fill
            className="!relative h-full w-full"
          />
        ) */}
        <div className="!relative flex w-full items-start">
          <FooterAnimation />
        </div>
      </div>
      {/* <div className="prose fixed bottom-12 z-[100] hidden md:block ltr:right-12 rtl:left-16"> */}
      {/*   {isIframeVisible && ( */}
      {/*     <div className="h-full min-h-112"> */}
      {/*       <div className="flex h-14 flex-row justify-between bg-text-accent p-4"> */}
      {/*         <span className="rtl!tracking-normal body-1-variant !font-normal leading-medium !text-white ltr:tracking-widest"> */}
      {/*           Live Chat */}
      {/*         </span> */}
      {/*         <button type="button" onClick={handleCloseClick}> */}
      {/*           <Icons icon="menu-close" className="svg-path:stroke-white" /> */}
      {/*         </button> */}
      {/*       </div> */}
      {/*       <iframe */}
      {/*         src="https://livechat.dwtc.com/csportalclient/refimplementation/LiveChatOnecentral.html" */}
      {/*         data-deferred="true" */}
      {/*         className="h-106 w-full" */}
      {/*       /> */}
      {/*     </div> */}
      {/*   )} */}
      {/*   <div className="flex w-full justify-end"> */}
      {/*     <button type="button" className="chat-button" onClick={handleIconClick}> */}
      {/*       <Icons icon="live-chat" className="h-22 w-22 cursor-pointer" /> */}
      {/*     </button> */}
      {/*   </div> */}
      {/* </div> */}
    </footer>
  );
}
