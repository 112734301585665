import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from '@/assets/styles/wordfader.module.css';

const isArabic = (text) => /[\u0600-\u06FF]/.test(text);

const WordFader = ({ words }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      },
      words[currentWordIndex].length * 250,
    );

    return () => clearInterval(intervalId);
  }, [currentWordIndex]);

  const renderWord = (word) => {
    if (isArabic(word)) {
      return (
        <span className={`relative inline-block text-text-accent`}>
          {word}
        </span>
      );
    }

    return (
      <span className={`relative inline-block text-text-accent`}>
        {word.split('').map((letter, index) => (
          <span
            key={index}
            className={`inline-block translate-y-5 opacity-0 ${styles.letter}`}
            style={{ animationDelay: `${index * 0.1}s` }}>
            {letter}
          </span>
        ))}
      </span>
    );
  };

  return (
    <div className="relative inline-block">
      <TransitionGroup>
        <CSSTransition
          key={words[currentWordIndex]}
          timeout={{ enter: 1000, exit: 300 }}
          classNames={
            isArabic(words[currentWordIndex])
              ? {
                  enter: styles.wordEnterComplete,
                  enterActive: styles.wordEnterCompleteActive,
                  exit: styles.wordExitComplete,
                  exitActive: styles.wordExitCompleteActive,
                }
              : {
                  enter: styles.wordEnter,
                  enterActive: styles.wordEnterActive,
                  exit: styles.wordExit,
                  exitActive: styles.wordExitActive,
                }
          }>
          {renderWord(words[currentWordIndex])}
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default WordFader;
