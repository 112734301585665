import React, { useContext } from 'react';
import Link from 'next/link';
import _ from 'lodash'
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import Context from '@/context';
import ResponsiveImage from './layouts/ResponsiveImage';

export default function TextContentWithLeftImage({ data, id }) {

  const title = _.get(data, 'title', null);
  const descriptionText = _.get(data, 'description', null);
  const images = _.get(data, 'images[0]', {});
  const link = _.get(data, 'link', '#0');
  const desktopVideo = _.get(data, 'desktopVideo', null);
  const mobileVideo = _.get(data, 'mobileVideo', null);
  const desktopVideoPoster = _.get(data, 'desktopVideoPoster.url', null);
  const mobileVideoPoster = _.get(data, 'mobileVideoPoster.url', null);
  const { lang } = useContext(Context);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);
  return (
    <section id={id} className="prose relative bg-white py-12 md:py-30">
      <div className="flex flex-col items-center gap-y-10 lg:flex-row lg:gap-x-12 lg:gap-y-0 xl:gap-x-20 2xl:gap-x-48 3xl:gap-x-72">
        <div className="px-8 lg:max-w-408 3xl:px-0 lg:ltr:ml-auto lg:rtl:mr-auto">
          <div className="flex flex-col gap-5 lg:max-w-132 lg:gap-8">
            <div className="flex flex-col gap-3 lg:gap-y-6">{title && <h2>{title}</h2>}</div>
            {descriptionText && (
              <div
                dangerouslySetInnerHTML={{ __html: descriptionText }}
                className="flex flex-col gap-6"
              />
            )}
            <Link
              href={link.url}
              target={link.target}
              className={cn(
                buttonVariants({ variant: 'secondary' }),
                'mt-4 hidden w-fit lg:block text-content-with-left-image text-text-accent',
                link.target === '_blank' && 'external-link',
              )}>
              {link.name}
            </Link>
          </div>
        </div>
        {desktopVideo && (
          <div className="hidden lg:block lg:w-1/2">
            <video
              muted
              autoPlay
              playsInline
              loop
              preload="metadata"
              crossOrigin="anonymous"
              poster={`${desktopVideoPoster}?width=400`}
              className="h-175 w-full object-cover ltr:lg:rounded-l-md 
              ltr:lg:rounded-r-none rtl:lg:rounded-l-none rtl:lg:rounded-r-md"
            >
              <source src={`/api/proxy-video?videoPath=${desktopVideo}`} type="video/mp4" />
            </video>
          </div>
        )}
        {mobileVideo && (
          <div className="block w-full px-8 lg:hidden">
            <video
              muted
              autoPlay
              playsInline
              loop
              preload="metadata"
              crossOrigin="anonymous"
              poster={mobileVideoPoster}
              className="h-62 w-full object-cover"
              >
                <source src={`/api/proxy-video?videoPath=${mobileVideo}`} type="video/mp4" />
            </video>
          </div>
        )}
        <div className="block w-full px-8 lg:hidden">
          <Link
            href={replaceUrl(link.url)}
            target={link.target}
            aria-label="two-cards-link"
            className={`${buttonVariants({ variant: 'secondary' })} two-cards-link w-fit`}>
            {link.name}
          </Link>
        </div>
      </div>
    </section>
  );
}
