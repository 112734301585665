import _ from 'lodash';

export default function getUpcomingEvents(events) {
  const currentDate = new Date();

  // filter out past events
  const upcomingEvents = _.filter(events, (event) => {
    const endDate = new Date(event.endDate);
    return endDate > currentDate;
  });

  return _.sortBy(upcomingEvents, [
    (event) => new Date(event.startDate),
    (event) => new Date(event.endDate),
  ]);
}
