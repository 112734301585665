import React from 'react';
import _ from 'lodash';
import dynamic from 'next/dynamic';

const ContactLocationDesktop = dynamic(() => import('./ContactLocationDesktop'), {
  ssr: false,
  loading: () => '',
});

const ContactLocationMobile = dynamic(() => import('./ContactLocationMobile'), {
  ssr: false,
  loading: () => '',
});

export default function ContactLocation({ data, id }) {
  const items = _.get(data, 'items', null);
  return (
    <section id={id} className="map-section relative overflow-hidden">
      <ContactLocationDesktop data={items} />
      <ContactLocationMobile data={items} />
    </section>
  );
}
