import dynamic from 'next/dynamic';

import CTAWithImageComposition from '@/components/CTAWithImageComposition/CTAWithImageComposition';
import CardsWithTextContentCTA from '@/components/CardsWithTextContentCTA';
import CertificationRecognition from '@/components/CertificationRecognition/CertificationRecognition';
import CompanyRegisterPagination from '@/components/CompanyRegisterPagination';
import ContactLocation from '@/components/ContactLocation/ContactLocation';
import DwtcEvents from '@/components/DWTCEvents/DwtcEvents';
import EventsList from '@/components/EventsList';
import EventsTwentyFiveHours from '@/components/EventsTwentyFiveHours/EventsTwentyFiveHours';
import FeaturedServicesSlider from '@/components/FeaturedServicesSlider';
import HomeBanner from '@/components/HomeBanner';
import IbisOneCentral from '@/components/IbisOneCentral/IbisOneCentral';
import ImageWithOverlayCard from '@/components/ImageWithOverlayCard';
import InstaFeed from '@/components/InstaFeed';
import LeftImageWithTextContentCTA from '@/components/LeftImageWithTextContentCTA';
import LocationShowcase from '@/components/LocationShowcase/LocationShowcase';
import OurTenants from '@/components/OurTenants';
import PageBanner from '@/components/PageBanner';
import SustainabilityOverview from '@/components/SustainabilityOverview';
import Testimonials from '@/components/Testimonials/Testimonials';
import TextContentCTAWithImages from '@/components/TextContentCTAWithImages';
import TextContentCTAWithSlider from '@/components/TextContentCTAWithSlider/TextContentCTAWithSlider';
import TextContentWithAccordion from '@/components/TextContentWithAccordion';
import TextContentWithHorizontalSlider from '@/components/TextContentWithHorizontalSlider';
import TextContentWithLeftImage from '@/components/TextContentWithLeftImage';
import TextContentWithTwoCards from '@/components/TextContentWithTwoCards';
import TextContentWithVideo from '@/components/TextContentWithVideo';
import TextWithVideo from '@/components/TextWithVideo';
import TitleWithItems from '@/components/TitleWithItems';
import TwoColumnTextCTAWithSlider from '@/components/TwoColumnTextCTAWithSlider';
import TwoColumnTextContentWithVideo from '@/components/TwoColumnTextContentWithVideo';
import TwoColumnTextWithBgImage from '@/components/TwoColumnTextWithBgImage';
import WorldClassOffices from '@/components/WorldClassOffices';
import SiteMap from '@/components/SiteMap';
import HtmlContent from '@/components/HtmlContent';
import SearchResults from '@/components/SearchResults/SearchResults';
import NearCityAttractions from '@/components/NearCityAttractions/NearCityAttractions';
import LocationMaps from '@/components/LocationsMaps/LocationMaps';

const ContactForm = dynamic(() => import('@/components/ContactForm'), {ssr: false});
const GetInTouchForm = dynamic(() => import('@/components/GetInTouchForm'), {ssr: false});
const MapWithCards = dynamic(() => import('@/components/MapWithCards'), {ssr: false});

export default {
  CTAWithImageComposition,
  CardsWithTextContentCTA,
  CertificationRecognition,
  CompanyRegisterPagination,
  ContactLocation,
  DwtcEvents,
  EventsList,
  EventsTwentyFiveHours,
  FeaturedServicesSlider,
  GetInTouchForm,
  HomeBanner,
  IbisOneCentral,
  ImageWithOverlayCard,
  InstaFeed,
  LeftImageWithTextContentCTA,
  LocationShowcase,
  MapWithCards,
  OurTenants,
  PageBanner,
  SustainabilityOverview,
  Testimonials,
  TextContentCTAWithImages,
  TextContentCTAWithSlider,
  TextContentWithAccordion,
  TextContentWithHorizontalSlider,
  TextContentWithLeftImage,
  TextContentWithTwoCards,
  TextContentWithVideo,
  TextWithVideo,
  TitleWithItems,
  TwoColumnTextCTAWithSlider,
  TwoColumnTextContentWithVideo,
  TwoColumnTextWithBgImage,
  WorldClassOffices,
  SiteMap,
  HtmlContent,
  SearchResults,
  NearCityAttractions,
  LocationMaps,
  ContactForm
};
