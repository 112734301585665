import React from 'react';
import _ from 'lodash';
import Image from 'next/image';

import CertificationRecognitionItemAward from './CertificationRecognitionItemAward';

export default function CertificationRecognitionItem({ data, border }) {
  const image = _.get(data, 'content.image', null);
  const awards = _.get(data, 'content.awards', null);

  return (
    <div
      className={`flex flex-col lg:flex-row lg:items-center ${
        _.size(awards) > 2
          ? 'w-full justify-center gap-x-20'
          : '!w-min items-center gap-x-3 lg:!w-fit'
      }`}>
      <div className="lg:h-31 mb-4 w-24 min-w-24 lg:mb-0 lg:w-34 lg:min-w-34">
        {_.isEmpty(image) || (
          <Image
            src={image.url}
            alt="award image"
            fill
            className="!relative h-full w-full object-cover"
          />
        )}
      </div>

      <div className="flex flex-row flex-wrap gap-x-6 gap-y-8 lg:gap-x-6 4xl:gap-x-8 w-full">
        {_.isEmpty(awards) ||
          _.map(awards, (award, index) => (
            <CertificationRecognitionItemAward
              key={index}
              data={award}
              border={border}
              awards={awards}
            />
          ))}
      </div>
    </div>
  );
}
