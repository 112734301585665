import React from 'react';
import Image from 'next/image';
import { motion } from 'framer-motion';
import _ from 'lodash';
import useElementSize from '@/hooks/useElementSize';
import Link from 'next/link';
import { buttonVariants } from '@/components/ui/button';
import { dateToString, convertMonthToAr } from '@/helpers/formatDate';

export default function EventCard({ data, clickHandler, open, dir }) {
  const title = _.get(data, 'title', '');
  const description = _.get(data, 'description', '');
  const imageUrl = _.get(data, 'featuredImage.url', '');
  const eventTime = _.get(data, 'eventTime', '');
  const eventDate = _.get(data, 'eventDate', '');
  const eventLocation = _.get(data, 'eventLocation', '');
  const eventWebsite = _.get(data, 'eventWebsite', '');
  const eventGoogleMap = _.get(data, 'eventGoogleMap', {});

  const [ref, width, height] = useElementSize();

  return (
    <button type="button" onClick={clickHandler} className="relative h-full w-full overflow-hidden">
      {imageUrl && (
        <Image src={imageUrl} alt="background layer" fill className="!object-cover" priority />
      )}

      <div
        data-open={open}
        className={`overlay-card relative z-10 flex h-full w-full flex-col justify-end overflow-auto transition-transform ${open ? 'transform-none' : 'translate-y-[calc(100%-72px)] lg:translate-y-[calc(100%-92px)]'} ${open ? 'bg-text-accent' : 'bg-text-primary'}`}>
        <div
          data-open={open}
          className="flex h-auto w-full flex-col justify-between p-6 text-text-invert lg:p-7">
          <div className="flex w-full items-center justify-between">
            {title && (
              <h4
                data-open={open}
                className="text-start text-clamp16to32 !font-normal !tracking-normal text-text-invert transition-all data-[open=false]:line-clamp-1">
                {title}
              </h4>
            )}
            <i
              data-open={open}
              className="transition-all data-[open=false]:rotate-0 data-[open=true]:rotate-180">
              <i className="one-central-arrow-small text-[9px] text-text-invert" />
            </i>
          </div>
        </div>
        <div ref={ref} className="flex h-full w-full grow flex-col px-6 pb-6 lg:px-7 lg:pb-7">
          {description && (
            <p className="pb-4 text-start text-clamp14to16 !font-light leading-medium !tracking-normal text-text-invert lg:pb-5">
              {description}
            </p>
          )}
          <ul className="m-0 flex flex-col gap-2 pb-4 pt-5 text-clamp14to16 md:gap-3 ltr:pl-0 rtl:pr-0">
            {_.isEmpty(eventTime) || (
              <li className="m-0 flex items-center gap-2 p-0">
                <i className="one-central-clock text-[20px] text-text-invert lg:text-[24px]" />
                <span className="font-light leading-medium text-text-invert rtl:font-sans-ar rtl:text-[14px]">
                  {eventTime}
                </span>
              </li>
            )}
            {!_.isEmpty(eventDate) && eventDate !== '0001-01-01T00:00:00Z' && (
              <li className="m-0 flex items-center gap-2 p-0">
                <i className="one-central-clock text-[20px] text-text-invert lg:text-[24px]" />
                <span className="font-light leading-medium text-text-invert rtl:font-sans-ar rtl:text-[14px]">
                  {dir === 'rtl'
                    ? convertMonthToAr(dateToString(eventDate))
                    : dateToString(eventDate)}
                </span>
              </li>
            )}
            {_.isEmpty(eventLocation) || (
              <li className="m-0 flex items-center gap-2 p-0">
                <i className="one-central-map-pin text-[20px] text-text-invert lg:text-[24px]" />
                <span className="font-light leading-medium text-text-invert rtl:font-sans-ar rtl:text-[14px]">
                  {eventLocation}
                </span>
              </li>
            )}

            {_.isEmpty(eventWebsite) || (
              <li className="m-0 flex items-center gap-2 p-0">
                <i className="one-central-link text-[20px] text-text-invert lg:text-[24px]" />
                <a
                  dir="ltr"
                  href={`https://www.${eventWebsite}`}
                  className="tracking-normal text-text-invert underline ltr:text-clamp14to16 ltr:!font-normal rtl:font-sans-ar rtl:text-[14px] rtl:!font-light"
                  target="_blank"
                  rel="noreferrer">
                  {eventWebsite}
                </a>
              </li>
            )}
          </ul>
          {_.isEmpty(eventGoogleMap) || (
            <Link
              href={eventGoogleMap.url}
              target="_blank"
              className={`${buttonVariants({ variant: 'secondary' })} map-with-cards-item-button external-link w-fit border !py-3 px-6 text-[14px] hover:border-white md:mt-2`}
              aria-label="map-with-cards-item-button">
              {eventGoogleMap.name}
            </Link>
          )}
        </div>
      </div>
    </button>
  );
}
