import _ from 'lodash';

const allMonthsEnAr = {
  Jan: 'يناير',
  Feb: 'فبراير',
  Mar: 'مارس',
  Apr: 'أبريل',
  May: 'مايو',
  Jun: 'يونيو',
  Jul: 'يوليو',
  Aug: 'أغسطس',
  Sep: 'سبتمبر',
  Oct: 'أكتوبر',
  Nov: 'نوفمبر',
  Dec: 'ديسمبر',
};

function formatDate(date) {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return new Date(date).toLocaleDateString('en-US', options);
}

export const getEventDateRange = (event) => {
  const startDate = new Date(event.startDate);
  const endDate = new Date(event.endDate);

  if (_.isEqual(startDate, endDate)) {
    return formatDate(startDate);
  }

  const startDay = startDate.getDate();
  const startMonth = startDate.toLocaleString('en-US', { month: 'short' });
  const startYear = startDate.getFullYear();

  const endDay = endDate.getDate();
  const endMonth = endDate.toLocaleString('en-US', { month: 'short' });
  const endYear = endDate.getFullYear();

  if (startMonth === endMonth && startYear === endYear) {
    return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
  }

  if (startYear === endYear) {
    return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
  }

  return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
}
export const convertMonthToAr = (date) => {
  const dateArr = date.split(' ');
  const newDate = dateArr.map((item, index) => {
    if (_.has(allMonthsEnAr, item)) {
      return allMonthsEnAr[item];
    }
    return item;
  });
  return newDate.join(' ');
};
export const dateToString = (event) => {
  const date = new Date(event);
  if (_.isEqual(date)) {
    return formatDate(date);
  }
  const startDay = date.getDate();
  const startMonth = date.toLocaleString('en-US', { month: 'short' });
  const startYear = date.getFullYear();
  return `${startDay} ${startMonth} ${startYear}`;
};