import _ from 'lodash';
import { motion } from 'framer-motion';
import React from 'react';
import { usePathname } from 'next/navigation';

export default function TextWithVideo({ data, id }) {
  const text = _.get(data, 'videoText', null);
  const videoUrl = _.get(data, 'videoUrl', '');
  const mobileVideoUrl = _.get(data, 'mobileVideoUrl', '');
  const videoPoster = _.get(data, 'videoPoster.url', '');
  const mobileVideoPoster = _.get(data, 'mobileVideoPoster.url', '');
  const isPaddingBottom = _.get(data, 'isPaddingBottom', '');
  const pathname = usePathname();
  const isServices = pathname.includes('serviced-office-spaces');
  return (
    <section
      id={id}
      className={`prose flex flex-col bg-background-base 
      ${isPaddingBottom ? 'pb-12 lg:pb-30' : ''}`}>
      {_.isEmpty(videoUrl) || (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.6 }}
          viewport={{ once: true }}
          className="relative hidden w-full md:block">
          <video
            muted
            autoPlay
            playsInline
            loop
            preload="metadata"
            poster={`${videoPoster}?width=600`}
            crossOrigin="anonymous"
            className="w-full object-cover"
            >
              <source src={`/api/proxy-video?videoPath=${videoUrl}`} type="video/mp4" />
          </video>
        </motion.div>
      )}
      {_.isEmpty(mobileVideoUrl) || (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.6 }}
          viewport={{ once: true }}
          className="relative block w-full md:hidden">
          <video
            muted
            autoPlay
            playsInline
            loop
            preload="metadata"
            poster={`${mobileVideoPoster}?width=320`}
            crossOrigin="anonymous"
            className="w-full object-cover"
            >
              <source src={`/api/proxy-video?videoPath=${mobileVideoUrl}`} type="video/mp4" />
          </video>
        </motion.div>
      )}
      {_.isEmpty(text) || (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.6 }}
          viewport={{ once: true }}
          className={`relative mx-auto px-8 py-12 lg:py-30 3xl:px-0 rtl:lg:pl-47 ${isServices ? 'max-w-301.5 3xl:max-w-353' : 'max-w-353 3xl:max-w-385'}`}>
          <h3 className="!font-light !leading-medium tracking-normal md:!leading-semi-medium">
            {text}
          </h3>
        </motion.div>
      )}
    </section>
  );
}
