/* eslint-disable import/no-unresolved */

'use client';

import _ from 'lodash';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { motion } from 'framer-motion';
import Link from 'next/link';
import Image from 'next/image';
import { buttonVariants } from '@/components/ui/button';
import Context from '@/context';
import Head from 'next/head';
import Icons from './layouts/Icons';

const HomeBannerAnimation = dynamic(() => import('./layouts/HomeBannerAnimation'), {
  ssr: false,
});

export default function HomeBanner({ data, id }) {
  const items = _.get(data, 'items', []);
  if (_.isEmpty(items)) return null;
  const totalLength = items?.length;
  const [activeIndex, setActiveIndex] = useState(1);
  const percentageWidth = (activeIndex / totalLength) * 100;
  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex + 1);
  };

  const breakpoints = {
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
  };

  const { lang } = useContext(Context);

  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);
  return (
    <section
      id={id}
      className="relative overflow-hidden bg-background-primary before:absolute before:top-0 before:z-10 before:!block before:h-[245px] before:w-full before:bg-custom-gradient before:content-[''] md:min-h-[550px] lg:h-[80vh] before:lg:!hidden xl:min-h-[660px] 2xl:min-h-[720px] 2xll:h-[90vh] 2xll:min-h-[760px]">
      <div className="grid h-full grid-flow-row-dense lg:grid-cols-2">
        <div className="2xll:max-w-non order-last flex flex-col pt-10 lg:order-first lg:max-w-[115%] lg:pt-44 2xl:pt-60 3xl:items-end 3xl:pt-52 ltr:2xll:pr-20 ltr:3xl:pr-28 rtl:pl-8 rtl:2xll:pl-20 rtl:3xl:pl-28">
          <div className="prose order-first pb-12 lg:order-last lg:max-w-167 lg:pb-0 ltr:lg:pl-8 ltr:3xl:pl-4 rtl:lg:pr-8 rtl:3xl:pr-4">
            {_.isEmpty(items) || (
              <Swiper
                breakpoints={breakpoints}
                onSlideChange={handleSlideChange}
                className="mySwiper lg:max-w-167">
                {_.map(items, (item, index) => {
                  const itemTitle = _.get(item, 'content.title', null);
                  const itemText = _.get(item, 'content.htmlText', null);
                  const itemLinks = _.get(item, 'content.links', []);
                  return (
                    <SwiperSlide key={index} className="!w-full">
                      <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: 0.6 }}
                        viewport={{ once: true }}
                        className="flex flex-col gap-6 px-8 lg:px-0 ltr:md:gap-y-6 ltr:xl:gap-y-12 rtl:md:gap-y-8">
                        {itemTitle && <h1>{itemTitle}</h1>}
                        <div className="flex flex-col gap-y-8 ltr:md:gap-y-8 ltr:lg:pl-10 ltr:xl:gap-y-14 ltr:xl:pl-35 rtl:md:gap-y-12 rtl:lg:pr-10 rtl:xl:pr-35">
                          {itemText && (
                            <div className="" dangerouslySetInnerHTML={{ __html: itemText }} />
                          )}
                          {_.isEmpty(itemLinks) || (
                            <div className="flex w-full flex-col gap-4 pb-10 md:w-auto md:flex-row md:gap-6">
                              {_.map(itemLinks, (link, linkIndex) => {
                                const linkUrl = _.get(link, 'url', '#');
                                const linkTarget = _.get(link, 'target', '_self');
                                const linkLabel = _.get(link, 'name', null);
                                return (
                                  linkLabel && (
                                    <Link
                                      key={linkIndex}
                                      href={replaceUrl(linkUrl)}
                                      target={linkTarget}
                                      className={`${buttonVariants({ variant: 'primary' })} home-banner-button flex w-full justify-center md:w-fit`}
                                      aria-label="home-banner-banner">
                                      {linkLabel}
                                    </Link>
                                  )
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </motion.div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
            {_.size(items) > 1 && (
              <div className="max-w-560 body-1-variant flex flex-row items-center gap-4 pt-7 !text-text-primary md:pt-20 ltr:lg:pl-10 ltr:xl:pl-35 rtl:lg:pr-10 rtl:xl:pr-35">
                <span>{`${_.padStart(activeIndex, 2, '0')}`}</span>
                <div className="h-0.5 w-23 bg-background-tertiary">
                  <div
                    className="bg-primary h-0.5 transition-all duration-300"
                    style={{ width: `${percentageWidth}%` }}
                  />
                </div>
                <span>{`${_.padStart(totalLength, 2, '0')}`}</span>
              </div>
            )}
          </div>
        </div>
        <Head>
          <link
            fetchPriority="high"
            rel="preload"
            as="image"
            href={items[activeIndex - 1]?.content?.image?.url || ''}
          />
          <link
            fetchPriority="high"
            rel="preload"
            as="image"
            href={items[activeIndex - 1]?.content?.mobileImage?.url || ''}
          />
        </Head>
        <div className="relative w-full overflow-hidden md:h-full lg:max-w-[85%] 2xll:max-w-none ltr:lg:ml-auto ltr:lg:mr-0 rtl:lg:ml-0 rtl:lg:mr-auto">
          {!_.isEmpty(items[activeIndex - 1].content.image) ? (
            <>
              <video
                muted
                playsInline
                autoPlay
                loop
                preload="metadata"
                poster={items[activeIndex - 1].content.image?.url}
                crossOrigin="anonymous"
                className="hidden !h-auto min-h-115 w-full object-cover md:block lg:!h-full">
                <source
                  src={`/api/proxy-video?videoPath=${items[activeIndex - 1]?.content?.videoUrl}`}
                  type="video/mp4"
                />
              </video>
              <div className="relative md:hidden min-h-115">
                <Image
                  src={`/${items[activeIndex - 1]?.content?.mobileImage?.url}?width=430`}
                  placeholder="blur"
                  blurDataURL={`${items[activeIndex - 1]?.content?.mobileImage?.url}?width=43`}
                  quality={50}
                  fill
                  alt="One Central - Mobile Video"
                  className="absolute bottom-0 left-0 right-0 top-0 z-0 aspect-video !h-auto min-h-115 w-full object-cover"
                />
                <video
                  width={430}
                  height={400}
                  muted
                  playsInline
                  autoPlay
                  loop
                  preload="auto"
                  loading="lazy"
                  crossOrigin="anonymous"
                  className="relative z-10 aspect-video !h-auto min-h-115 w-full object-cover">
                  <source
                    src={`/api/proxy-video?videoPath=${items[activeIndex - 1]?.content?.mobileVideoUrl}`}
                    type="video/mp4"
                  />
                </video>
              </div>
            </>
          ) : (
            <Image
              src={items[activeIndex - 1]?.content?.image?.url}
              placeholder="blur"
              blurDataURL={`${items[activeIndex - 1]?.content?.image?.url}?width=96`}
              quality={100}
              alt="Banner Image"
              fill
              className="!relative !h-auto min-h-115 w-full object-cover"
              />
            )
          }
          <div className="absolute bottom-0 flex h-1/2 w-[60%] items-end justify-end opacity-100 mix-blend-multiply lg:hidden lg:h-auto ltr:right-0 rtl:left-0 rtl:scale-x-[-1]">
            <HomeBannerAnimation />
          </div>
        </div>
      </div>
      <div className="home-banner absolute bottom-0 hidden aspect-square h-1/2 items-end justify-end opacity-100 mix-blend-multiply lg:flex ltr:right-0 rtl:left-0 rtl:scale-x-[-1]">
        <HomeBannerAnimation />
      </div>
    </section>
  );
}
