import React, { useState, useContext } from 'react';
import _ from 'lodash';
import getTranslationByKey from '@/helpers/getTranslationByKey';
import Context from '@/context';

export default function Pagination({
  data,
  RenderComponent,
  dataLimit,
  wrapperClass,
  itemsWrapperClass,
  showCount,
  activeTab,
  setActiveTab,
}) {
  const { translations, lang } = useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / dataLimit);

  const goToNextPage = () => setCurrentPage((page) => Math.min(page + 1, totalPages));
  const goToPreviousPage = () => setCurrentPage((page) => Math.max(page - 1, 1));
  const changePage = (event) => setCurrentPage(Number(event.target.textContent));

  const getPaginatedData = () =>
    _(data)
      .slice((currentPage - 1) * dataLimit)
      .take(dataLimit)
      .value();

  const getPaginationGroup = () => {
    let pages = [];

    if (totalPages <= 7) {
      // If total temp.pages is 7 or less, show all temp.pages
      pages = _.range(1, totalPages + 1);
    } else {
      // Determine temp.pages based on current page
      const startPages = _.range(1, 3); // First 2 temp.pages
      const endPages = _.range(totalPages - 1, totalPages + 1); // Last 2 temp.pages
      let middlePages;

      if (currentPage <= 4) {
        // Close to start
        middlePages = _.range(1, 6);
      } else if (currentPage > totalPages - 4) {
        // Close to end
        middlePages = _.range(totalPages - 4, totalPages + 1);
      } else {
        // Somewhere in the middle
        middlePages = _.range(currentPage - 1, currentPage + 2);
      }

      pages = _.uniq([...startPages, ...middlePages, ...endPages]);

      // Insert ellipses where there are gaps
      const withEllipsis = [];
      _.forEach(pages, (page, index) => {
        // Add current page
        withEllipsis.push(page);

        // Add ellipses if there's a gap
        if (index < pages.length - 1 && pages[index + 1] !== page + 1) {
          withEllipsis.push('...');
        }
      });

      pages = withEllipsis;
    }

    return pages;
  };

  return (
    <div className={wrapperClass}>
      {showCount && (        
        lang === 'ar' ? (
          <p className="body-1-variant mb-4 !font-bold">
            إظهار {_.size(getPaginatedData())} من {_.size(data)} شركة
          </p>
        ) : (
          <p className="body-1-variant mb-4 !font-bold">
            Showing {_.size(getPaginatedData())} of {_.size(data)} companies
          </p>
        )
      )}

      <div className={itemsWrapperClass}>
        {getPaginatedData().map((d, idx) => (
          <RenderComponent key={idx} data={d} index={idx} activeTab={activeTab}
          setActiveTab={setActiveTab}/>
        ))}
      </div>

      {data.length > 0 && (
        <div className="flex w-full justify-center gap-x-4 md:gap-x-10 pt-8 lg:pt-20 px-5">
          <button
            type="button"
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
            className="text-clamp14to24 font-bold tracking-normal disabled:opacity-40">
            {getTranslationByKey('Previous', translations, lang)}
          </button>

          <div className="flex gap-x-3 md:gap-x-5">
            {getPaginationGroup().map((item, index) => (
              <button
                key={index}
                type="button"
                onClick={item === '...' ? () => {} : changePage}
                disabled={currentPage === item}
                data-ellipsis={item === '...'}
                className="flex h-6 lg:h-10 w-6 lg:w-10 items-center justify-center border border-text-accent text-clamp14to20 font-bold disabled:bg-text-accent disabled:text-background-base data-[ellipsis=true]:cursor-default
                text-text-accent">
                {item}
              </button>
            ))}
          </div>

          <button
            type="button"
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            className="text-clamp14to24 font-bold tracking-normal disabled:opacity-40">
            {getTranslationByKey('Next', translations, lang)}
          </button>
        </div>
      )}
    </div>
  );
}
