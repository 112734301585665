import React from 'react';
import { cn } from '@/lib/utils';

function BoxedContainer({ children, className = '' }) {
  const defaultClasses = 'mx-auto w-full px-8 3xl:px-16';
  const classes = cn(defaultClasses, className);
  return <div className={classes}>{children}</div>;
}

export default BoxedContainer;
