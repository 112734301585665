'use client';

import React, { useEffect, useRef } from 'react';
import Image from 'next/image';
import _ from 'lodash';
import dynamic from 'next/dynamic';

import BoxedContainer from '@/components/layouts/BoxedContainer';
import { usePathname } from 'next/navigation';

const CTAWithImageCompositionItem = dynamic(() => import('./CTAWithImageCompositionItem'), {
  ssr: false,
  loading: () => <p>Loading...</p>,
});

export default function CTAWithImageComposition({ data, id }) {
  const title = _.get(data, 'title', null);
  const items = _.get(data, 'itemsContent', null);
  const bgImages = _.get(data, 'bgImages', null);
  const parallaxRef = useRef(null);

  const pathname = usePathname();
  const isBusiness = pathname.includes('business');
  const isCommunity = pathname.includes('community');

  useEffect(() => {
    const handleScroll = () => {
      if (parallaxRef.current) {
        const rect = parallaxRef.current.getBoundingClientRect();
        const offset = window.pageYOffset - rect.top;
        parallaxRef.current.style.transform = `translateY(-${offset * 0.1}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section
      id={id}
      className="prose relative w-full gap-y-20 overflow-hidden bg-background-brand-3 py-12 lg:py-30">
      {title && <h2 className="px-8 pb-10 text-center lg:pb-16">{title}</h2>}
      {!_.isEmpty(bgImages) && (
        <div
          ref={parallaxRef}
          className="absolute top-1/3 h-270 w-51 xl:h-full xl:max-h-367 
          xl:w-full xl:max-w-368 ltr:left-auto ltr:right-0 rtl:left-0 rtl:right-auto">
          <Image src={bgImages.url} alt="Background Image" fill className="object-cover" />
        </div>
      )}
      <BoxedContainer 
      className={`relative flex flex-col gap-y-10 3xl:max-w-408 
      ${isCommunity ? ' lg:gap-y-12 ' : ' lg:gap-y-20 '} `}>
        {!_.isEmpty(items) &&
          _.map(items, (item, index) => (
            <CTAWithImageCompositionItem key={index} data={item} index={index} />
          ))}
      </BoxedContainer>
    </section>
  );
}
