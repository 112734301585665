import React from 'react';
import { motion } from 'framer-motion';
import _ from 'lodash';
import ResponsiveImage from './layouts/ResponsiveImage';


export default function TwoColumnTextWithBgImage({ data, id }) {
  const title = _.get(data, 'title', null);
  const textOne = _.get(data, 'textOne', null);
  const textTwo = _.get(data, 'textTwo', null);
  const images = _.get(data, 'images[0]', {});
  return (
    <section id={id} className="prose py-12 md:py-30">
      <div className="mx-auto max-w-398 px-8 3xl:px-0">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          viewport={{ once: true }}
          className="flex max-w-339 flex-col gap-y-5 md:gap-y-8">
          {title && (
            <span className="body-1-variant !leading-medium md:!leading-default">{title}</span>
          )}

          {!textTwo 
          ? <p className='md:columns-2 md:gap-20 2xl:gap-24' dangerouslySetInnerHTML={{ __html: textOne }} />
          : <div className="flex flex-col gap-4 md:flex-row md:gap-6">
          {textOne && <p className="md:w-1/2 p-br-child:2xl:block p-br-child:hidden" dangerouslySetInnerHTML={{ __html: textOne }} />}
          {textTwo && <p className="md:w-1/2 p-br-child:2xl:block p-br-child:hidden" dangerouslySetInnerHTML={{ __html: textTwo }} />}
        </div>
          }

        </motion.div>
        
        {_.isEmpty(images) || (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.4 }}
            viewport={{ once: true }}
            className="pt-10 md:pt-30">
            <ResponsiveImage
              desktopImage={images?.content?.image?.url}
              mobileImage={images?.content?.mobileImage?.url}
              alt={title}
              className="!h-62 rounded-sm object-cover md:!h-full"
            />
          </motion.div>
        )}
      </div>
    </section>
  );
}
