import _ from 'lodash';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import React, { useContext } from 'react';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import ResponsiveImage from './layouts/ResponsiveImage';
import Context from '@/context';

export default function TitleWithItems({ data, id }) {
  const title = _.get(data, 'title', null);
  const items = _.get(data, 'items', []);
  const bgColor = _.get(data, 'bgColor', []);
  const background = bgColor ? `bg-${bgColor.label}` : 'bg-background-primary';
  const bgImages = _.get(data, 'imageBlock[0]', {});
  const link = _.get(data, 'link', null);
  const { lang } = useContext(Context);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);

  return (
    <section id={id} className={`prose ${background} relative py-12 md:pb-25 md:pt-30`}>
      {_.isEmpty(bgImages) || (
        <div className="absolute left-0 top-0 h-full max-h-full w-full overflow-hidden">
          <ResponsiveImage
            desktopImage={bgImages?.content?.image?.url}
            mobileImage={bgImages?.content?.mobileImage?.url}
            alt={title}
            className="w-full object-cover"
          />
        </div>
      )}
      <div className="mx-auto flex max-w-339 flex-col gap-y-8 px-8 md:gap-y-16 3xl:px-0">
        {title && (
          <motion.h2
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            viewport={{ once: true }}
            className="text-center">
            {title}
          </motion.h2>
        )}
        {_.isEmpty(items) || (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.4 }}
            viewport={{ once: true }}
            className={`flex flex-row flex-wrap justify-center gap-x-4 gap-y-8 ${items.length > 6 ? 'md:gap-x-16 md:gap-y-14' : 'md:gap-x-8 md:gap-y-14'}`}>
            {_.map(items, (itemList, index) => {
              const icon = _.get(itemList, 'content.icon', {});
              const iconTitle = _.get(itemList, 'content.iconTitle', null);

              return (
                <div
                  key={index}
                  className={`flex w-[calc(50%-8px)] flex-col items-center gap-4 text-center ${items.length > 6 ? 'px-4 md:w-68' : 'md:w-85'} md:gap-8`}>
                  {!_.isEmpty(icon) && (
                    <Image
                      src={icon.url}
                      alt={iconTitle}
                      fill
                      className="!relative !h-10 !w-10 md:!h-12 md:!w-12"
                    />
                  )}
                  {iconTitle && (
                    <h5 className="!text-clamp18to24 !tracking-normal rtl:!leading-medium">
                      {iconTitle.split('|').map((part, i) => (
                        <React.Fragment key={i}>
                          {part}
                          {i < iconTitle.split('|').length - 1 && (
                            <br className="hidden md:block" />
                          )}
                        </React.Fragment>
                      ))}
                    </h5>
                  )}
                </div>
              );
            })}
          </motion.div>
        )}

        {_.isEmpty(link) || (
          <div className="w-full self-center lg:w-fit">
            <Link
              href={replaceUrl(link.url)}
              target={link.target}
              className={cn(
                buttonVariants({ variant: 'secondary' }),
                '!lg:w-fit title-with-items w-full',
                link.target === '_blank' && 'external-link',
              )}>
              {link.name}
            </Link>
          </div>
        )}
      </div>
    </section>
  );
}
