import React from 'react';
import { useRive } from '@rive-app/react-canvas';

export default function HeaderAnimation() {
  const { rive, RiveComponent } = useRive(
    {
      src: 'https://media.umbraco.io/dwtc/hecpvcue/onecentral_headeranimation_v3.riv',
      stateMachines: 'State Machine 1',
      autoplay: true,
    },
    { fitCanvasToArtboardHeight: true },
  );

  return <RiveComponent />;
}
