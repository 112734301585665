import _ from 'lodash';
import React from 'react';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import Icons from '../layouts/Icons';

export default function TextContentCTAWithSliderMobile({ data,replaceUrl }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: 0.4 }}
      viewport={{ once: true }}
      className="relative z-10 mx-auto flex w-full max-w-409 flex-col gap-8 px-8 md:hidden 3xl:px-0">
      {_.map(data, (item, index) => {
        const sliderImage = _.get(item, 'content.image', {});
        const sliderTitle = _.get(item, 'content.title', null);
        const sliderText = _.get(item, 'content.text', null);
        const sliderLink = _.get(item, 'content.link', null);
        return (
          <div key={index} className="group flex flex-col gap-y-4">
            <div className="min-h-50">
              {_.isEmpty(sliderImage) || (
                <Image
                  src={`${sliderImage.url}?width=320`}
                  placeholder="blur"
                  blurDataURL={`${sliderImage.url}?width=32`}
                  quality={100}
                  alt={sliderTitle}
                  fill
                  className="!relative !h-50 w-full rounded-md object-cover md:!h-full"
                />
              )}
            </div>
            <div className="flex flex-col justify-between gap-4">
              <div className="flex w-full flex-row justify-between gap-4">
                {sliderTitle && (
                  <h3 className="text-nowrap tracking-normal group-hover:text-text-accent group-hover:transition-all group-hover:duration-200">
                    {sliderTitle}
                  </h3>
                )}
                {_.isEmpty(sliderLink) || (
                  <Link
                    href={replaceUrl(sliderLink.url)}
                    target={sliderLink.target}
                    aria-label="home-full-width-slider-link"
                    className="home-full-width-slider-link">
                    <Icons icon="arrow" className="h-5 w-6 min-w-6 rtl:scale-[-1] group-hover:svg-path:fill-text-accent
                      group-hover:svg-path:transition-all group-hover:svg-path:duration-200" />
                  </Link>
                )}
              </div>
              {sliderText && <p className="rtl:text-clamp18to24">{sliderText}</p>}
            </div>
          </div>
        );
      })}
    </motion.div>
  );
}
