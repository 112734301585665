import React from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import BoxedContainer from './layouts/BoxedContainer';
import Link from 'next/link';
import Head from 'next/head';

export default function TextContentWithVideo({ data }) {
  const title = _.get(data, 'title', null);
  const bodyText = _.get(data, 'text', null);
  const videoUrl = _.get(data, 'videoUrl', '');
  const mobileVideoUrl = _.get(data, 'mobileVideoUrl', '');
  const videoPoster = _.get(data, 'videoPoster.url', '');
  const mobileVideoPoster = _.get(data, 'mobileVideoPoster.url', '');

  return (
    <section id="text-content-with-video" className="bg-background-brand-1 pt-10 md:pt-30">
      <BoxedContainer>
        <div className="prose cards flex justify-center">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            viewport={{ once: true }}
            className="flex w-full flex-col items-center justify-center gap-y-4 md:max-w-408 md:gap-y-6">
            {title && <span className="body-1-variant">{title}</span>}
            {bodyText && (
              <h2 className="text-center !font-light !leading-medium tracking-normal md:!leading-semi-medium md:leading-semi-medium ltr:md:tracking-tight">
                {bodyText}
              </h2>
            )}
          </motion.div>
        </div>
      </BoxedContainer>
      <Head>
        <link fetchPriority="high" rel="preload" as="image" href={videoPoster} />
        <link fetchPriority="high" rel="preload" as="image" href={mobileVideoPoster} />
      </Head>
      {_.isEmpty(videoUrl) || (
        <div className="hidden pt-12 md:block lg:pt-30">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.4 }}
            viewport={{ once: true }}
            className="relative">
            <video
              muted
              autoPlay
              playsInline
              loop
              preload="metadata"
              poster={videoPoster}
              crossOrigin="anonymous"
              className="h-50 w-full object-cover md:h-full">
              <source src={`/api/proxy-video?videoPath=${videoUrl}`} type="video/mp4" />
            </video>
          </motion.div>
        </div>
      )}
      {_.isEmpty(mobileVideoUrl) || (
        <div className="block pt-12 md:hidden lg:pt-30">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.4 }}
            viewport={{ once: true }}
            className="relative">
            <video
              muted
              autoPlay
              playsInline
              loop
              preload="metadata"
              poster={mobileVideoPoster}
              crossOrigin="anonymous"
              className="h-62 min-h-[280px] w-full object-cover md:h-full">
              <source src={`/api/proxy-video?videoPath=${mobileVideoUrl}`} type="video/mp4" />
            </video>
          </motion.div>
        </div>
      )}
    </section>
  );
}
