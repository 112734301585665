import React from 'react';
import _ from 'lodash';
import BoxedContainer from '@/components/layouts/BoxedContainer';
import Image from 'next/image';
import { motion } from 'framer-motion';

export default function SustainabilityOverview({ data, id, dir }) {
  const title = _.get(data, 'title', '');
  const text = _.get(data, 'text', '');
  const desktopImageUrl = _.get(data, 'images[0].content.image.url', '');
  const mobileImageUrl = _.get(data, 'images[0].content.mobileImage.url', '');
  const features = _.get(data, 'features', []);
  const numberOfItems = features.length;
  const remainder = numberOfItems % 3;

  return (
    <section id={id} className="prose bg-background-brand-5 py-14 xl:py-30">
      <BoxedContainer
        className={`flex flex-col gap-10 lg:gap-16 ${numberOfItems > 3 ? 'lg:max-w-408 3xl:max-w-408' : '!max-w-408'}`}>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          viewport={{ once: true }}
          className={`flex flex-col gap-6 px-0 ${numberOfItems > 3 ? 'lg:px-44' : 'lg:px-34'}`}>
          <h2 className="text-center">{title}</h2>
          <p className="text-center">{text}</p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          viewport={{ once: true }}
          className="relative aspect-23by15 md:aspect-102by25">
          {mobileImageUrl && (
            <Image
              alt="sustainability-mobile-image"
              src={mobileImageUrl}
              fill
              className="md:!hidden"
            />
          )}
          {desktopImageUrl && (
            <Image
              alt="sustainability-image"
              src={desktopImageUrl}
              fill
              className="!hidden md:!block"
            />
          )}
        </motion.div>
        <div
          className={`flex flex-col gap-10 lg:grid lg:grid-cols-3 ${features.length > 3 ? 'lg:gap-10 lg:px-21' : 'lg:gap-[6%]'}`}>
          {_.map(features, (feature, index) => {
            const featureTitle = _.get(feature, 'content.title', '');
            const featureText = _.get(feature, 'content.text', '');
            const featureIcon = _.get(feature, 'content.iconClass', '');
            const featureUseCustomIcon = _.get(feature, 'content.useCustomIcon', false);
            const featureCustomIcon = _.get(feature, 'content.customIcon', null);
            let shouldOffset = false;

            if (remainder === 1) {
              shouldOffset = index === numberOfItems - 1;
            } else if (remainder === 2) {
              shouldOffset = index === numberOfItems - 2 || index === numberOfItems - 1;
            }

            return (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 * index, delay: 0.3 }}
                viewport={{ once: true }}
                key={index}
                className={`flex flex-col items-center text-center ${shouldOffset ? 'lg:col-start-2' : ''}`}>
                {featureUseCustomIcon ? (
                  !_.isEmpty(featureCustomIcon) && (
                    <Image
                      src={featureCustomIcon.url}
                      alt={featureTitle}
                      fill
                      className="!relative !h-10 !w-10 md:!h-12 md:!w-12"
                    />
                  )
                ) : (
                  <i className={`${featureIcon} text-[40px] text-text-accent md:text-[48px]`} />
                )}
                <h3 className="pt-4 text-clamp20to24 font-semibold md:pt-7 rtl:font-sans-ar">
                  {featureTitle}
                </h3>
                <p className="pt-4 text-lg leading-medium md:pt-6">{featureText}</p>
              </motion.div>
            );
          })}
        </div>
      </BoxedContainer>
    </section>
  );
}
