'use client';

import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import getTranslationByKey from '@/helpers/getTranslationByKey';
import Context from '@/context';
import Pagination from '@/components/layouts/Pagination';
import dynamic from 'next/dynamic';

const CompanyRegisterItem = dynamic(() => import('./CompanyRegisterItem'), {
  ssr: false,
  loading: () => <p>Loading...</p>,
});

export default function Index({ id, data }) {
  const { translations, lang } = useContext(Context);
  const title = _.get(data, 'title', null);
  const items = _.get(data, 'items', null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const dataLimit = 20;
  const pageLimit = Math.ceil(items.length / dataLimit);
  const [searchTerm, setSearchTerm] = useState('');

  const searchItems = async () => {
    const filtered = items.filter((item) => item.content.title.toLowerCase().includes(searchTerm.toLowerCase()));

    setFilteredItems(filtered);
  };

  useEffect(() => {
    searchItems();
  }, [searchTerm]);

  useEffect(() => {
    if (items) {
      setFilteredItems(items);
    }
  }, [items]);
  const filterItemsDesktop = _.chunk(filteredItems, 2);

  return (
    <section id={id} className="bg-base prose relative w-full gap-y-20 pb-12 lg:pb-30">
      <div className="relative h-22 w-full bg-background-primary">
        <input
          type="text"
          className="input h-full w-full border bg-background-primary pl-6 pr-12 placeholder:text-secondary"
          placeholder={getTranslationByKey('Search by company names', translations, lang)}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className="absolute top-0 flex h-full items-center justify-center ltr:right-6 rtl:left-6">
          <i className="one-central-search text-[18px]" />
        </div>
      </div>

      <div className="relative mx-auto flex max-w-92 flex-col divide-y px-5 pt-14 md:max-w-155 lg:max-w-235.5 lg:px-0 lg:pt-25 2xl:max-w-339">
        {title && <h3 className="pb-10 !text-clamp28to40 !font-normal lg:pb-12">{title}</h3>}

        <Pagination
          data={filteredItems}
          showCount
          RenderComponent={CompanyRegisterItem}
          itemsWrapperClass="w-full h-auto lg:block flex-col gap-y-5 lg:gap-y-14 pt-10 lg:pt-12 lg:columns-2"
          wrapperClass="pt-10 lg:pb-10"
          prevLabel="first"
          nextLabel="last"
          pageLimit={pageLimit}
          dataLimit={dataLimit}
          activeTab={activeTab} // Pass activeTab
          setActiveTab={setActiveTab} // Pass setActiveTab
        />
      </div>
    </section>
  );
}
