import React, { useContext } from 'react';
import _ from 'lodash';
import Link from 'next/link';
import getTranslationByKey from '@/helpers/getTranslationByKey';
import Context from '@/context';

export default function SearchResultsItem({ data }) {
  const { translations, lang } = useContext(Context);
  console.log('data', data);
  const title = _.get(data, 'title', null);
  const description = _.get(data, 'description', null);
  const url = _.get(data, '_url', null);
  console.log('Original URL:', url);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);
  const contentTypeAlias = _.get(data, 'contentTypeAlias', null);
  const isRetail = contentTypeAlias === 'oneCentralRetail';
  
  let newUrl = url;
  if (isRetail && url) {
    const urlParts = url.split('/');
    const lastPart = urlParts.pop() || urlParts.pop(); // Handle trailing slash
    const basePath = urlParts.join('/');
    newUrl = `${basePath}/?item=${title}`;
  }
  
  return (
    <div className="flex flex-col border-b pb-8 lg:flex-row lg:gap-x-5 lg:pb-10">
      <div className="mb-3 flex w-full lg:mb-0 lg:max-w-97">
        <h4 className="!text-clamp20to32 !font-normal tracking-tight">{title}</h4>
      </div>

      <div className="flex flex-col gap-y-5 lg:gap-y-6">
        <p className="subtitle !text-clamp16to20 !font-light leading-medium lg:!font-normal">
          {description}
        </p>

        <Link
          href={replaceUrl(newUrl)}
          aria-label="404-button"
          className="flex gap-x-4 uppercase text-text-accent !tracking-normal">
          {getTranslationByKey('Visit link', translations, lang)}

          <i className="one-central-arrow-large rtl:rotate-180" />
        </Link>
      </div>
    </div>
  );
}
