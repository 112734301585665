'use client';

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Icons from '../layouts/Icons';

export default function HeaderDesktop({ data, lang, isHome }) {
  const [selected, setSelected] = useState(null);
  const pathname = usePathname();
  const slug = _.chain(pathname).split('/').filter(Boolean).value();
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);

  const regions = {
    ar: { oppositeLabel: 'EN', oppositeLang: 'en' },
    en: { oppositeLabel: 'AR', oppositeLang: 'ar' },
  };

  const clonedSlug = _.cloneDeep(slug);
  _.set(clonedSlug, 0, regions[lang]?.oppositeLang);
  const redirectionPath = _.join(clonedSlug, '/');

  const langLabel = _.get(regions, `${lang}.oppositeLabel`, 'ar');
  const parentPath = `/${_.chain(slug).take(2).join('/').value()}/`;
  const path = `/${_.chain(slug).join('/').trim('/').value()}/`;

  const handleParentSelect = () => {
    if (isHome) setSelected(null);
    else {
      _.forEach(data, (item) => {
        const linkUrl = _.get(item, 'content.link.url', null);
        console.log(linkUrl);
        if (_.isEqual(replaceUrl(linkUrl), parentPath)) {
          setSelected(item);
        }
      });
    }
  };

  useEffect(() => {
    _.forEach(data, (item) => {
      const linkUrl = _.get(item, 'content.link.url', null);
      if (_.isEqual(replaceUrl(linkUrl), parentPath)) {
        setSelected(item);
      }
    });
  }, []);
  

  return (
    <div className="prose flex flex-row items-center justify-between gap-4 xl:gap-12 2xll:gap-52">
      <div className="flex flex-row gap-10 xl:gap-20 2xll:gap-24 3xl:gap-32">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          viewport={{ once: true }}>
          <Link href={`/${lang}`} aria-label='Home Page'>
            <Icons icon="logo" className="h-18 2xl:h-24" />
          </Link>
        </motion.div>

        {_.isEmpty(data) || (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            viewport={{ once: true }}
            className="relative flex w-163 flex-row items-center gap-x-6 2xl:gap-x-8 2xll:gap-x-10 xl:w-193 ltr:3xl:gap-x-14 rtl:3xl:gap-x-12">
            {_.map(data, (item, index) => {
              const link = _.get(item, 'content.link', null);
              const itemChildren = _.get(item, 'content.itemChildren', []);

              return (
                <div
                  key={index}
                  onMouseEnter={() => setSelected(item)}
                  onMouseLeave={handleParentSelect}
                  className="h-fit pb-5">
                  <Link
                    href={replaceUrl(link.url)}
                    target={link.target}
                    data-curr-parent={parentPath === replaceUrl(link.url)}
                    className="header-menu hover:text-text-accent data-[curr-parent=true]:text-text-accent">
                    {link.name}
                  </Link>
                  {_.isEqual(item, selected) && (
                    <div className="absolute flex w-full flex-row gap-x-4 2xll:gap-x-8 pt-6 ltr:left-0 ltr:3xl:gap-x-14 rtl:right-0 rtl:3xl:gap-x-12">
                      {_.map(itemChildren, (subChildren, subChildrenIndex) => {
                        const subChildrenLink = _.get(subChildren, 'content.link', null);

                        return (
                          <Link
                            key={subChildrenIndex}
                            href={replaceUrl(subChildrenLink.url)}
                            target={subChildrenLink.target}
                            data-currpath={replaceUrl(subChildrenLink.url) === path}
                            className="header-children-menu !font-light hover:text-text-accent data-[currPath=true]:text-text-accent">
                            {subChildrenLink.name}
                          </Link>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </motion.div>
        )}
      </div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.4 }}
        viewport={{ once: true }}
        className="flex flex-row items-center gap-4">
        <Link href={`/${lang}/search`} aria-label="Search">
          <Icons icon="search" className="h-10 w-10" />
        </Link>
        <Link
          aria-label={langLabel}
          href={`/${redirectionPath}`}
          className="body-3 flex h-10 w-10 items-center justify-center rounded-full bg-background-black !font-medium uppercase text-white rtl:pt-1">
          {langLabel}
        </Link>
      </motion.div>
    </div>
  );
}
