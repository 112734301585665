'use client';

import React, { Suspense, useMemo } from 'react';
import _ from 'lodash';
import Context from '@/context';
import COMPONENTS from '@/components';
import Header from '@/components/Header/Header';
import Footer from '@/components/Footer';

export default function IndexPage({
  data = {},
  lang = 'en',
  settings = {},
  specificData = {},
  isHome = false,
}) {
  const { bodyContent, pageId, title, description, featuredImage } = data;
  const { translations } = settings;
  const memo = useMemo(() => ({ lang, translations }), [lang, translations]);
  const dir = lang === 'en' ? 'ltr' : 'rtl';

  return (
    <Context.Provider value={memo}>
      <title>{title || 'One Central'}</title>
      {description && <meta name="description" content={description} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {featuredImage && <meta property="og:image" content={featuredImage.url} />}
      <meta property="og:type" content="website" />
      <Suspense>
        <main className='overflow-x-hidden relative'>
          <Header data={settings} lang={lang} pageId={pageId} isHome={isHome} />
          {_.map(bodyContent, (item, index) => {
            if (_.isEmpty(item)) return null;
            const type = _.replace(_.get(item, 'content.__typename', ''), 'OneCentral', '');
            const blockContent = _.get(item, 'content');
            const COMP = COMPONENTS[type];
            const id = `${_.kebabCase(type)}-${index + 1}`;
            return (
              _.isFunction(COMP) && (
                <COMP key={index} id={id} data={blockContent} 
                dir={dir} specificData={specificData} />
              )
            );
          })}
          <Footer data={settings} dir={dir} />
        </main>
      </Suspense>
    </Context.Provider>
  );
}
