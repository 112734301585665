import React, { useContext, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import _ from 'lodash';
import Image from 'next/image';
import 'swiper/css';
import Link from 'next/link';
import { buttonVariants } from '@/components/ui/button';
import useCustomCursor from '@/hooks/use-custom-cursor';
import { motion } from 'framer-motion';
import BoxedContainer from '@/components/layouts/BoxedContainer';
import Context from '@/context';

export default function FeaturedServicesSlider({ id, data }) {
  const items = _.get(data, 'items', []);
  const { toggleCursor } = useCustomCursor();

  useEffect(() => {
    toggleCursor(false);
    return () => toggleCursor(false);
  }, []);

  const handleLinkMouseEnter = () => {
    toggleCursor(false);
  };

  const handleLinkMouseLeave = () => {
    toggleCursor(true);
  };

  const breakpoints = {
    0: {
      slidesOffsetBefore: 32,
      slidesOffsetAfter: 32,
    },
    1920: {
      slidesOffsetBefore: 140,
      slidesOffsetAfter: 140,
    },
  };
  const { lang } = useContext(Context);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);

  return (
    <section id={id} className="prose py-14 lg:py-30">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.3 }}
        viewport={{ once: true }}
        className="hidden md:block"
        onMouseEnter={() => toggleCursor(true)}
        onMouseLeave={() => toggleCursor(false)}>
        <Swiper slidesPerView="auto" spaceBetween={24} breakpoints={breakpoints}>
          {_.map(items, (item, index) => {
            const itemImageUrl = _.get(item, 'content.image.url', null);
            const itemTitle = _.get(item, 'content.title', null);
            const itemText = _.get(item, 'content.text', null);
            const cta = _.get(item, 'content.cta', null);

            return (
              <SwiperSlide
                key={index}
                className="!max-w-92 lg:!max-w-96 xl:!max-w-112 2xl:!max-w-132 min-h-full !h-auto">
                <div className="!flex !h-full !grow !flex-col gap-12">
                  <div
                    className="relative aspect-11by8"
                    onMouseEnter={() => toggleCursor(true)}
                    onMouseLeave={() => toggleCursor(false)}>
                    <Image src={itemImageUrl} alt={itemTitle}
                    priority
                    fill className="!object-cover rounded aspect-11by8" />
                  </div>
                  <div className='flex h-full flex-col gap-12 justify-between'>
                    <div className="flex h-full grow flex-col gap-6">
                      <h4>{itemTitle}</h4>
                      <p className="">{itemText}</p>
                    </div>
                    {_.isEmpty(cta) || (
                      <Link
                        href={replaceUrl(cta.url)}
                        target={cta.target}
                        className={`${buttonVariants({ variant: 'secondary' })} w-fit`}
                        onMouseEnter={handleLinkMouseEnter}
                        onMouseLeave={handleLinkMouseLeave}>
                        {cta.name}
                      </Link>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </motion.div>
      <BoxedContainer>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          viewport={{ once: true }}
          className="flex flex-col gap-8 md:hidden"
          onMouseEnter={() => toggleCursor(true)}
          onMouseLeave={() => toggleCursor(false)}>
          {_.map(items, (item, index) => {
            const itemImageUrl = _.get(item, 'content.image.url', null);
            const itemTitle = _.get(item, 'content.title', null);
            const itemText = _.get(item, 'content.text', null);
            const cta = _.get(item, 'content.cta', null);

            return (
              <div key={index} className="w-full">
                <div className="!flex !h-full !grow !flex-col gap-8 lg:gap-12">
                  <div className="relative aspect-11by8">
                    <Image src={itemImageUrl} alt={itemTitle} fill className="!object-cover" />
                  </div>
                  <div className="flex h-full grow flex-col gap-3 lg:gap-6">
                    <h2 className="font-medium ltr:text-clamp28to32">{itemTitle}</h2>
                    <p className="text-text-secondary ltr:text-clamp16to18">{itemText}</p>
                  </div>
                  {_.isEmpty(cta) || (
                    <Link
                      href={replaceUrl(cta.url)}
                      target={cta.target}
                      className={`${buttonVariants({ variant: 'secondary' })} mt-2 w-fit lg:mt-0`}
                      onMouseEnter={handleLinkMouseEnter}
                      onMouseLeave={handleLinkMouseLeave}>
                      {cta.name}
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
        </motion.div>
      </BoxedContainer>
    </section>
  );
}
