/* eslint-disable import/no-unresolved */

'use client';

import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import _ from 'lodash';
import { motion } from 'framer-motion';
import Link from 'next/link';
import Image from 'next/image';
import useCustomCursor from '@/hooks/use-custom-cursor';
import Icons from '../layouts/Icons';
import Head from 'next/head';

export default function TextContentCTAWithSliderDesktop({ data, replaceUrl }) {
  const { toggleCursor } = useCustomCursor();

  useEffect(() => {
    toggleCursor(false);
    return () => toggleCursor(false);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: 0.4 }}
      viewport={{ once: true }}
      className="z-10 mx-auto hidden max-w-409 px-8 md:block 3xl:px-0">
      <div className="cards flex flex-row gap-6 pt-2">
        {_.map(data, (item, index) => {
          const sliderImage = _.get(item, 'content.image', {});
          const sliderTitle = _.get(item, 'content.title', null);
          const sliderText = _.get(item, 'content.text', null);
          const sliderLink = _.get(item, 'content.link', null);
          return (
            <div
              key={index}
              className="group relative w-1/3 transition-all delay-200 duration-1000">
              <Head>
                <link fetchPriority="high" rel="preload" as="image" href={sliderImage?.url} />
              </Head>
              <div className="flex flex-col gap-y-6">
                <div className="min-h-560">
                  {_.isEmpty(sliderImage) || (
                    <Image
                      src={`${sliderImage?.url}?width=530`}
                      placeholder="blur"
                      blurDataURL={`${sliderImage?.url}?width=53`}
                      quality={100}
                      alt={sliderTitle}
                      fill
                      className="min-h-560 !relative h-full w-full rounded-md object-cover"
                    />
                  )}
                </div>
                <div className="flex flex-col gap-4 ltr:pr-5 xl:ltr:pr-6 rtl:pl-5 xl:rtl:pl-6">
                  <div className="flex flex-row justify-between gap-x-10 xl:gap-x-16">
                    {sliderTitle && (
                      <h3 className="text-nowrap group-hover:text-text-accent group-hover:transition-all group-hover:duration-200">
                        {sliderTitle}
                      </h3>
                    )}
                    {_.isEmpty(sliderLink) || (
                      <Link
                        href={replaceUrl(sliderLink?.url)}
                        target={sliderLink?.target}
                        aria-label="home-full-width-slider-link"
                        className="home-full-width-slider-link after:z-5 flex after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:!inline after:content-['']">
                        <Icons
                          icon="arrow"
                          className="w-9 group-hover:svg-path:fill-text-accent group-hover:svg-path:transition-all group-hover:svg-path:duration-200 rtl:scale-[-1]"
                        />
                      </Link>
                    )}
                  </div>
                  {sliderText && (
                    <p className="xl:max-w-528 max-w-400 rtl:!text-clamp16to18">{sliderText}</p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </motion.div>
  );
}
