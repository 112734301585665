import React from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import Image from 'next/image';
import BoxedContainer from './layouts/BoxedContainer';
import HeaderAnimation from './Header/HeaderAnimation';

export default function PageBanner({ data, id }) {
  const title = _.get(data, 'title', null);
  const htmlText = _.get(data, 'bannerText', null);
  const image = _.get(data, 'image', {});
  return (
    <section id={id} className="relative overflow-hidden bg-background-primary lg:!min-h-118">
      <div className="prose relative z-10 bg-background-primary bg-opacity-90 pb-12 pt-35 md:pb-20 md:pt-53 lg:bg-opacity-0">
        <BoxedContainer>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.6 }}
            viewport={{ once: true }}
            className="relative mx-auto flex max-w-112 flex-col gap-y-6 text-center md:gap-y-8 xl:max-w-137 3xl:max-w-201">
            {title && <h1 className="text-center">{title}</h1>}
            {htmlText && (
              <h5 className="text-center !font-light !tracking-normal !text-text-secondary rtl:!leading-medium">
                {htmlText.split('||').map((part, index) => (
                  <React.Fragment key={index}>
                    {part}
                    {index < htmlText.split('||').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </h5>
            )}
          </motion.div>
        </BoxedContainer>
      </div>
      <div className="absolute left-0 top-0 h-full !w-84 object-cover div-canvas:!h-full first-div:!h-full md:!w-136 ltr:!left-auto ltr:right-0 ltr:lg:-right-44 ltr:xl:-right-25 ltr:2xl:-right-16 ltr:3xl:right-0 rtl:!right-auto rtl:scale-x-[-1] rtl:lg:-left-44 rtl:xl:-left-25 rtl:2xl:-left-16 rtl:3xl:!left-0">
        <HeaderAnimation />
      </div>
    </section>
  );
}
