import React, { useRef, useState } from 'react';
import _ from 'lodash';
import Icons from '@/components/layouts/Icons';
import Image from 'next/image';

export default function TestimonialsMobileItem({ data }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const ref = useRef(null);
  const videoUrl =
    _.get(data, 'content.mobileVideoUrl', null) || _.get(data, 'content.videoUrl', null);
  const videoPoster = _.get(data, 'content.videoPoster', null)
  const personImage = _.get(data, 'content.personImage.url', null);
  const personName = _.get(data, 'content.personName', null);
  const personPosition = _.get(data, 'content.personPosition', null);
  const handlePlay = () => {
    if (ref && ref.current) {
      // console.log(ref.current.currentTime);
      if (isPlaying) ref.current.pause();
      else ref.current.play();
      setIsPlaying((prev) => !prev);
    }
  };

  return (
    <div className="flex flex-col gap-y-6">
      {videoUrl && (
        <button type="button" className="relative" onClick={handlePlay}>
          <video
            ref={ref}
            muted
            playsInline
            loop
            preload="metadata"
            poster={videoPoster}
            crossOrigin="anonymous"
            className="aspect-video h-50 w-full rounded-sm object-cover"
          >
            <source src={`/api/proxy-video?videoPath=${videoUrl}`} type="video/mp4" />
          </video>
          {isPlaying || (
            <div className="absolute inset-0 flex items-center justify-center">
              <Icons icon="play-icon" className="scale-[0.60]" />
            </div>
          )}
        </button>
      )}
      <div className="flex flex-row items-center justify-center gap-x-6">
        <div className="bg-primary broder-2 border-primary-black flex h-14 w-14 items-center justify-center rounded-full">
          {personImage && (
            <Image src={personImage} alt={personName} fill className="!relative h-full w-full" />
          )}
        </div>
        <div className="flex flex-col gap-2">
          {personName && <h5 className="!font-normal tracking-normal">{personName}</h5>}
          {personPosition && <p className="!text-text-accent">{personPosition}</p>}
        </div>
      </div>
    </div>
  );
}
