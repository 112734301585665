import React from 'react';
import { useRive, Fit } from '@rive-app/react-canvas';

export default function FooterAnimation() {
  const { rive, RiveComponent } = useRive(
    {
      src: 'https://media.umbraco.io/dwtc/vyckrwxb/onecentral_footeranimation_v2.riv',
      stateMachines: 'State Machine 1',
      autoplay: true,
    },
    { fitCanvasToArtboardHeight: true },
  );

  return <RiveComponent />;
}
