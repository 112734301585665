import Image from 'next/image';
import React, { useState } from 'react';
import _ from 'lodash';
import { motion, AnimatePresence } from 'framer-motion';
import Icons from './layouts/Icons';

export default function TextContentWithAccordion({ data, id }) {
  const title = _.get(data, 'title', null);
  const descriptionContent = _.get(data, 'descriptionContent', null);
  const items = _.get(data, 'items', []);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <section id={id} className="prose flex flex-col gap-y-16">
      <div className="mx-auto flex max-w-329 flex-col gap-6 px-8 text-center 3xl:px-0">
        {title && <h2>{title}</h2>}
        {descriptionContent && <p>{descriptionContent}</p>}
      </div>

      {_.isEmpty(items) || (
        <div className="hidden bg-background-brand-3 lg:block">
          <div className="flex flex-col lg:flex-row">
            <div className="flex w-full flex-col px-8 py-12 lg:w-5/12 lg:max-w-208 lg:py-25 3xl:px-0 lg:ltr:ml-auto lg:rtl:mr-auto">
              <div className="flex flex-row flex-wrap gap-6 gap-x-4 lg:mx-auto lg:max-w-115 lg:flex-col lg:gap-10">
                {_.map(items, (item, index) => {
                  const accordionTitle = _.get(item, 'content.accordionTitle', null);
                  const accordionDescription = _.get(item, 'content.accordionDescription', null);
                  return (
                    <div
                      key={index}
                      className={`flex w-[calc(50%-8px)] cursor-pointer flex-col gap-2 transition-all duration-500 lg:w-full ${activeIndex === index ? 'active' : 'opacity-50'}`}
                      onClick={() => setActiveIndex(index)}>
                      {accordionTitle && (
                        <h3 className={`${activeIndex === index ? 'text-text-accent' : ''}`}>
                          {accordionTitle}
                        </h3>
                      )}
                      {accordionDescription && (
                        <p className="rtl:!text-clamp14to20">{accordionDescription}</p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="relative w-full lg:w-7/12">
              <AnimatePresence initial={false} custom={activeIndex}>
                <div key={activeIndex} className="absolute left-0 top-0 h-full w-full">
                  <div className="h-full w-full !overflow-hidden">
                    {items.map((item, index) =>
                      index === activeIndex ? (
                        <motion.div
                          key={index}
                          initial={{ opacity: 1, x: '100%' }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0.9 }}
                          transition={{
                            duration: 0.6,
                            ease: [0.420, 0.000, 0.580, 1.000],
                          }}
                          className={`relative left-0 top-0 h-full w-full ${index === activeIndex ? 'z-10' : 'z-0'}`}>
                          <Image
                            src={items[activeIndex].content.accordionImage.url}
                            alt={items[activeIndex].content.accordionTitle}
                            fill
                            className="object-cover"
                          />
                        </motion.div>
                      ) : (
                        ''
                      ),
                    )}
                  </div>

                  <div className="absolute -bottom-[17vh] z-20 mx-8 h-[260px] max-w-221 bg-black p-6 md:-bottom-24 lg:mx-0 lg:p-10 xl:p-16 overflow-hidden">
                    <motion.div
                      initial={{ x: '100%', opacity: 0 }}
                      animate={{ x: 0, opacity: 1, zIndex: 1 }}
                      transition={{
                        duration: 0.6,
                        delay: 0.05,
                        ease: [0.420, 0.000, 0.580, 1.000],
                      }}
                      className="flex flex-col gap-4">
                      <h2 className="text-white">{items[activeIndex].content.accordionTitle}</h2>
                      <p className="!text-background-tertiary rtl:!text-clamp14to20">
                        {items[activeIndex].content.accordionText}
                      </p>
                    </motion.div>
                  </div>
                </div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      )}
      {_.isEmpty(items) || (
        <div className="flex flex-col gap-1 lg:hidden">
          {_.map(items, (item, index) => {
            const accordionTitle = _.get(item, 'content.accordionTitle', null);
            const accordionDescription = _.get(item, 'content.accordionDescription', null);
            const accordionImage = _.get(item, 'content.accordionImage.url', null);
            const accordionText = _.get(item, 'content.accordionText', null);
            return (
              <div key={index} className="bg-background-brand-3 px-8 py-6">
                <div className="flex flex-col gap-y-5">
                  <div className="flex flex-col gap-3">
                    <button
                      type="button"
                      onClick={() => setActiveIndex(index)}
                      className="flex flex-row items-center justify-between gap-2">
                      <h5
                        className={`${activeIndex === index ? 'text-text-accent' : ''} text-clamp24to32 font-medium`}>
                        {accordionTitle}
                      </h5>
                      <Icons
                        icon={`${activeIndex === index ? 'arrowup' : 'arrowdown'}`}
                        className={`${activeIndex === index ? 'svg-path:fill-text-accent' : 'arrowdown'} w-5`}
                      />
                    </button>
                    <p>{accordionDescription}</p>
                  </div>
                  {activeIndex === index && (
                    <div className="flex flex-col gap-y-6">
                      {_.isEmpty(accordionImage) || (
                        <Image
                          src={accordionImage}
                          alt={accordionTitle}
                          fill
                          className="!relative !h-48 w-full object-cover lg:!h-full"
                        />
                      )}
                      <p>{accordionText}</p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
}
