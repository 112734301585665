import React, { useContext, useEffect, useState, useRef, lazy } from 'react';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import Context from '@/context';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import Image from 'next/image';

import getTranslationByKey from '@/helpers/getTranslationByKey';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import MobileMapTransform from '@/components/LocationsMaps/MobileMapTransform';
import useWindowResize from '@/hooks/use-window-resize';

export default function MobileMap({ data, items, MAP, isArabic }) {
  const { translations, lang } = useContext(Context);
  const transformRef = useRef(null);
  const { width } = useWindowResize();
  const isIpad = width >= 768;

  useEffect(() => {
    if (isIpad) {
      if (transformRef.current) {
        transformRef.current.setTransform(-1019.81, -90.68, 1.5);
      }
    } else {
      if (transformRef.current) {
        transformRef.current.setTransform(-1242, -124, 1.5);
      }
    }
  }, [isIpad]);

  const description = _.get(data, 'descriptionText', null);
  const link = _.get(data, 'link', null);
  const [pinItem, setPinItem] = useState(null);

  const onMapLoad = () => {
    const svgEl = document.querySelector('#location-map');
    const pins = document.querySelectorAll('[data-id="poi"]');

    _.forEach(pins, (pin) => {
      _.set(pin, 'style.cursor', 'pointer');
      const { nextSibling } = pin;
      _.set(nextSibling, 'style.cursor', 'pointer');

      pin.addEventListener('click', (event) => {
        const name = pin.getAttribute('data-name');
        const item = _.find(items, (val) => _.isEqual(_.kebabCase(val.title), _.kebabCase(name)));
        // console.log(item);

        if (!_.isEmpty(item)) {
          setPinItem(item);
          window.scrollTo({ top: 50, behavior: 'smooth' });
        }
        event.stopPropagation();
      });

      nextSibling.addEventListener('click', (event) => {
        pin.dispatchEvent(new Event('click', { bubbles: true }));
        event.stopPropagation();
      });
    });

    if (svgEl) {
      svgEl.addEventListener('click', (event) => {
        event.preventDefault();
        setPinItem(null);
      });
    }
  };

  useEffect(() => {
    onMapLoad();
  }, []);

  return (
    <div className="prose w-full overflow-hidden lg:hidden relative mobile-map">
      <div id="compass" className="absolute top-0 ltr:left-4 rtl:right-4 z-20">
        <Image src="/images/compass.svg" alt="Compass icon" width={85} height={75} />
      </div>
      <div id="drag" className="absolute top-[30px] left-1/2 -translate-x-1/2 z-30">
        <Image src="/images/map-drag.svg" alt="Compass icon" width={58} height={18} />
      </div>
      <div className="relative z-10">
        <AnimatePresence>
          {_.isNil(pinItem) ||
            (() => {
              const itemImageUrl = _.get(pinItem, 'featuredImage.url', null);
              const itemTitle = _.trim(_.get(pinItem, 'title', null));
              const itemDistance = _.get(pinItem, 'distance', null);
              const itemCTA = _.get(pinItem, 'cta', null);
              return (
                <motion.div
                  initial={{ opacity: 0, x: isArabic ? '100vw' : '-100vw' }}
                  animate={{ opacity: 1, x: isArabic ? '50%' : '-50%' }}
                  exit={{ opacity: 0, x: isArabic ? '-100vw' : '100vw' }}
                  transition={{ duration: 0.3 }}
                  key={pinItem.title}
                  data-arabic={isArabic}
                  className="absolute top-14 z-[9999] h-30 w-80 rounded-lg 
                  border-2 border-text-accent bg-white p-2 shadow-lg 
                  data-[arabic=false]:left-1/2 data-[arabic=true]:right-1/2">
                  <div className="relative flex h-full w-full items-center gap-2">
                    {itemImageUrl && (
                      <Image 
                        src={`${itemImageUrl}?width=246&height=150&mode=crop`} 
                        alt={itemTitle || 'Image'}
                        width={164} 
                        height={100}
                        priority
                        placeholder="blur" 
                        blurDataURL={`${itemImageUrl}?width=24&height=15&mode=crop`}
                        className='w-full h-full object-cover bg-slate-50'
                      />
                    )}
                    <div className="relative flex w-full flex-col items-center">
                      {itemTitle && (
                        <h2 className="text-[14px] uppercase tracking-wider text-text-accent">
                          {getTranslationByKey(itemTitle, translations, lang)}
                        </h2>
                      )}
                      {itemDistance && (
                        <span className="text-[11px] uppercase">
                          {`(${itemDistance} ${getTranslationByKey('mins', translations, lang)})`}
                        </span>
                      )}
                      {itemCTA && (
                        <a
                          href={itemCTA.url}
                          target={itemCTA.target}
                          className="mt-2 text-[11px] uppercase text-text-accent underline"
                          rel="noreferrer">
                          {getTranslationByKey(itemCTA.name, translations, lang)}
                        </a>
                      )}
                    </div>
                  </div>
                </motion.div>
              );
            })()}
        </AnimatePresence>
        <div dir="ltr">
          <TransformWrapper ref={transformRef} wheel={{ disabled: true }} maxScale={3}>
            <MobileMapTransform MAP={MAP} isArabic={isArabic} />
          </TransformWrapper>
        </div>
      </div>
      <div className="relative z-10 mx-auto flex max-w-339 flex-col items-center 
      gap-y-6 bg-white lg:gap-y-10 3xl:px-0 lg:-mt-[10.5vw]">
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className="mx-8 mt-8 flex flex-col gap-y-5 text-center p-child:text-clamp16to24"
        />
        {_.isEmpty(link) || (
          <Link
            href={link.url}
            target={link.target}
            className={cn(
              buttonVariants({ variant: 'primary' }),
              'w-fit',
              '!py-4',
              '!px-3',
              '!font-extralight',
              link.target === '_blank' && 'external-link-white-2',
            )}>
            {getTranslationByKey(link.name, translations, lang)}
          </Link>
        )}
      </div>
    </div>
  );
}
