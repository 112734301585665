'use client';

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import useScroll from '@/hooks/use-scroll';
import useWindowResize from '@/hooks/use-window-resize';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

export default function Header({ data, lang, pageId, isHome }) {
  const items = _.get(data, 'mainNavigation', []);
  const bottomLinks = _.get(data, 'bottomLinks', []);
  const instagram = _.get(data, 'instagram', '#0');
  const footerBackgroundImage = _.get(data, 'footerBackgroundImage', {});
  const { position, visible } = useScroll();
  const { width } = useWindowResize();
  const [initialScroll, setInitialScroll] = useState(0);

  useEffect(() => {
    setInitialScroll(window.scrollY);
  }, []);

  const isDesktop = width >= 1024;

  const headerClasses = () => {
    if (initialScroll > 50 || position > 50) {
      return 'bg-white py-6 pb-8';
    } 
      return 'bg-transparent pt-6 lg:pt-12';
    
  };

  return (
    <header
      data-page-id={pageId}
      id="header"
      data-visible={visible}
      data-near-top={position <= 50}
      className={`fixed top-0 z-[10000] w-full transition-all duration-300 ${
        visible ? 'translate-y-0' : '-translate-y-full'
      } ${headerClasses()}`}>
      <div className="mx-auto px-8 3xl:px-16">
        {isDesktop ? (
          <HeaderDesktop data={items} lang={lang} isHome={isHome} />
        ) : (
          <HeaderMobile
            data={items}
            position={position}
            lang={lang}
            bottomLinks={bottomLinks}
            instagram={instagram}
            isHome={isHome}
            footerBackgroundImage={footerBackgroundImage}
          />
        )}
      </div>
    </header>
  );
}
