import React, { useContext, useState } from 'react';
import dynamic from 'next/dynamic';
import _ from 'lodash';
import BoxedContainer from '@/components/layouts/BoxedContainer';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { buttonVariants } from '@/components/ui/button';
import Context from '@/context';

const RetailCard = dynamic(() => import('@/components/layouts/RetailCard'), {
  ssr: false,
  loading: () => '',
});

function CardsWithTextContentCTA({ id, data }) {
  const [selected, setSelected] = useState(null);
  const handleClick = (item) => {
    setSelected((prev) => {
      if (_.isEqual(prev, item)) return null;
      return item;
    });
  };

  const retails = _.get(data, 'retails', []);
  const title = _.get(data, 'title', '');
  const tagline = _.get(data, 'tagline', '');
  const text = _.get(data, 'text', '');
  const cta = _.get(data, 'cta', {});
  const { lang } = useContext(Context);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);
  return (
    <section id={id} className="prose py-12 xl:py-30">
      <BoxedContainer className="relative flex w-full flex-col gap-y-8 md:gap-y-10 xl:flex-row-reverse 3xl:!max-w-409">
        <div className="flex items-center justify-end xl:w-1/2 w-full">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            viewport={{ once: true }}
            className="flex-col xl:w-4/5 xl:ltr:text-left xl:rtl:text-right md:text-center w-full">
            {tagline && (
              <span className="body-1-variant mb-3 uppercase !leading-medium md:!leading-default">
                {tagline}
              </span>
            )}
            {title && (
              <h2 className="mt-4 text-clamp28to48 font-bold text-text-primary md:mt-5">{title}</h2>
            )}
            {text && (
              <p className="mt-5 text-text-secondary lg:mt-8 ltr:xl:w-2/3 rtl:xl:max-w-132">{text}</p>
            )}
            {_.isEmpty(cta) || (
              <Link
                href={replaceUrl(cta.url)}
                className={`${buttonVariants({ variant: 'secondary' })} map-with-cards-item-button mt-12 hidden w-fit px-6 xl:block ltr:text-[14px] rtl:text-clamp16to18`}
                aria-label="map-with-cards-item-button">
                {cta.name}
              </Link>
            )}
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          viewport={{ once: true }}
          className="hidden w-full auto-rows-fr grid-cols-1 gap-6 md:grid md:grid-cols-2 xl:w-1/2">
          {_.map(_.slice(retails, 0, 4), (item, index) => (
            <div key={index} className="min-h-99 xl:min-h-111">
              <RetailCard
                data={item}
                clickHandler={() => handleClick(item)}
                open={_.isEqual(item, selected)}
              />
            </div>
          ))}
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          viewport={{ once: true }}
          className="xl:!hidden md:flex md:justify-center md:items-center">
          {_.isEmpty(cta) || (
            <Link
              href={replaceUrl(cta.url)}
              className={`${buttonVariants({ variant: 'secondary' })} map-with-cards-item-button w-fit px-6 text-[14px]`}
              aria-label="map-with-cards-item-button">
              {cta.name}
            </Link>
          )}
        </motion.div>
      </BoxedContainer>
    </section>
  );
}

export default CardsWithTextContentCTA;
