import React, { useContext } from 'react';
import _ from 'lodash';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { buttonVariants } from '@/components/ui/button';
import TextContentCTAWithSliderDesktop from './TextContentCTAWithSliderDesktop';
import TextContentCTAWithSliderMobile from './TextContentCTAWithSliderMobile';
import Image from 'next/image';
import Context from '@/context';

export default function TextContentCTAWithSlider({ data }) {
  const subtitle = _.get(data, 'subtitle', null);
  const title = _.get(data, 'title', null);
  const text = _.get(data, 'htmlText', null);
  const bgImage = _.get(data, 'bgImage', {});
  const link = _.get(data, 'link', null);
  const items = _.get(data, 'items', []);
  const { lang } = useContext(Context);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);
  return (
    <section
      id="text-content-cta-with-slider"
      className="prose relative flex flex-col gap-y-8 overflow-hidden bg-background-brand-3 bg-opacity-60 py-12 md:gap-y-16 md:py-30">
      {_.isEmpty(bgImage) || (
        <Image
          src={bgImage.url}
          alt={title}
          fill
          className="max-w-4/6 absolute !top-48 bottom-0 !h-96 w-full max-w-24 object-cover md:!top-0 md:!h-full xl:max-w-329 ltr:!left-auto ltr:!right-0 rtl:!left-0 rtl:!right-auto"
        />
      )}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.3 }}
        viewport={{ once: true }}
        className="relative z-10 mx-auto flex w-full max-w-409 flex-col gap-2 px-8 lg:gap-6 3xl:px-0">
        {subtitle && <span className="body-1-variant">{subtitle}</span>}
        <div className="flex flex-col justify-between gap-6 md:flex-row lg:items-center lg:gap-40">
          <div className="md:max-w-666 flex flex-col gap-y-3 md:gap-y-6">
            {title && <h2 className="max-w-75 lg:max-w-97">{title}</h2>}
          </div>
          {text && <div className="ltr:max-w-166 rtl:max-w-142" dangerouslySetInnerHTML={{ __html: text }} />}
        </div>
      </motion.div>
      {_.isEmpty(items) || <TextContentCTAWithSliderDesktop data={items} replaceUrl={replaceUrl}/>}
      {_.isEmpty(items) || <TextContentCTAWithSliderMobile data={items} replaceUrl={replaceUrl}/>}
      {_.isEmpty(link) || (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.6 }}
          viewport={{ once: true }}
          className="z-10 flex justify-center px-8">
          <Link
            href={replaceUrl(link.url)}
            target={link.target}
            aria-label="text-content-cta-with-slider"
            className={`${buttonVariants({ variant: 'secondary' })} text-content-cta-with-slider w-full md:w-auto`}>
            {link.name}
          </Link>
        </motion.div>
      )}
    </section>
  );
}
