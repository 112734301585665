import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import useCustomCursor from '@/hooks/use-custom-cursor';
import Context from '@/context';

export default function TextContentCTAWithImages({ data, id }) {
  const title = _.get(data, 'title', null);
  const textContent = _.get(data, 'textContent', null);
  const images = _.get(data, 'images', {});
  const link = _.get(data, 'link', '#0');
  const { toggleCursor } = useCustomCursor();

  useEffect(() => {
    toggleCursor(false);
    return () => toggleCursor(false);
  }, []);
  const { lang } = useContext(Context);
  const replaceUrl = (url) => url.replace('/one-central/', `/${lang}/`);
  return (
    <section id={id} className="prose relative bg-white py-12 md:py-30">
      <div className="flex flex-col items-center gap-y-10 lg:flex-row lg:gap-x-12 lg:gap-y-0 xl:gap-x-20 2xl:gap-x-48 3xl:gap-x-72">
        <div className="px-8 lg:max-w-408 3xl:px-0 lg:ltr:ml-auto lg:rtl:mr-auto">
          <div className="flex flex-col gap-3 lg:max-w-132 lg:gap-8">
            <div className="flex flex-col gap-3 lg:gap-y-6">{title && <h2>{title}</h2>}</div>
            {textContent && <p>{textContent}</p>}
            <Link
              href={replaceUrl(link.url)}
              target={link.target}
              aria-label="two-cards-link"
              className={cn(
                buttonVariants({ variant: 'secondary' }),
                'text-content-cta-with-images mt-4 hidden w-fit lg:flex text-text-accent',
                link.target === '_blank' && 'external-link',
              )}>
              {link.name}
            </Link>
          </div>
        </div>
        {images && (
          <div
            className="w-full lg:w-1/2 lg:px-0 px-8">
            <Swiper className="ltr:lg:rounded-l-md ltr:lg:rounded-r-none rtl:lg:rounded-l-none rtl:lg:rounded-r-md">
              {_.map(images, (image, index) => (
                <SwiperSlide key={index}>
                  <Image
                    src={image.url}
                    alt={title}
                    fill
                    className="!relative !h-60 w-full rounded-md object-cover lg:!h-152.5"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
        <div className="block w-full px-8 lg:hidden">
          <Link
            href={replaceUrl(link.url)}
            target={link.target}
            aria-label="text-content-cta-with-images"
            className={cn(
              buttonVariants({ variant: 'secondary' }),
              'text-content-cta-with-images w-fit text-text-accent',
              link.target === '_blank' && 'external-link',
            )}>
            {link.name}
          </Link>
        </div>
      </div>
    </section>
  );
}
