import React from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import useCustomCursor from '@/hooks/use-custom-cursor';
import BoxedContainer from '@/components/layouts/BoxedContainer';
import getUpcomingEvents from '@/helpers/getUpcomingEvents';
import getEventDateRange from '@/helpers/formatDate';
import DwtcEventsDesktop from './DwtcEventsDesktop';
import DwtcEventsMobile from './DwtcEventsMobile';

export default function DwtcEvents({ id, data, specificData,dir }) {
  const title = _.get(data, 'title', '');
  const text = _.get(data, 'text', '');
  const dwtcEvents = _.get(specificData, 'dwtcEvents', []);
  const upcomingEvents = _.slice(getUpcomingEvents(dwtcEvents), 0, 21);
  const { toggleCursor } = useCustomCursor();

  const breakpoints = {
    0: {
      slidesOffsetBefore: 32,
      slidesOffsetAfter: 32,
      spaceBetween: 14,
    },
    1024: {
      spaceBetween: 24,
      slidesOffsetBefore: 140,
      slidesOffsetAfter: 140,
    },
    1920: {
      spaceBetween: 24,
      slidesOffsetBefore: 140,
      slidesOffsetAfter: 140,
    },
  };

  return (
    <section id={id} className="prose py-14 lg:py-30 bg-background-brand-3">
      <BoxedContainer>
        <div className="flex flex-col gap-6 text-center">
          <h2 className="!font-normal">{title}</h2>
          <p>{text}</p>
        </div>
      </BoxedContainer>
      <DwtcEventsDesktop data={data} specificData={specificData} dir={dir}/>
      <DwtcEventsMobile data={data} specificData={specificData} dir={dir}/>
    </section>
  );
}
