import dynamic from 'next/dynamic';
import React from 'react';
import _ from 'lodash';

const NearCityAttractionsDesktop = dynamic(() => import('./NearCityAttractionsDesktop'), {
  ssr: false,
  loading: () => '',
});

const NearCityAttractionsMobile = dynamic(() => import('./NearCityAttractionsMobile'), {
  ssr: false,
  loading: () => '',
});

export default function NearCityAttractions({ data, id, specificData }) {
  // console.log(data);
  const title = _.get(data, 'title', null);
  const items = _.get(data, 'items', []);
  const descriptionContent = _.get(data, 'descriptionContent', null);

  items.sort((valueA, valueB) => {
    const distanceValueA = _.chain(valueA)
      .get('content.time', null)
      .split(' ')
      .head()
      .toNumber()
      .value();
    const distanceValueB = _.chain(valueB)
      .get('content.time', null)
      .split(' ')
      .head()
      .toNumber()
      .value();
    return distanceValueA - distanceValueB;
  });

  return (
    <section id={id} className="prose bg-background-brand-1 py-12 lg:py-30">
      <div className="mx-auto flex max-w-307 flex-col gap-y-6 px-8 text-center lg:gap-y-8 3xl:px-0">
        {title && <h2>{title}</h2>}
        {descriptionContent && <p>{descriptionContent}</p>}
      </div>
      <NearCityAttractionsDesktop items={items} />
      <NearCityAttractionsMobile items={items} />
    </section>
  );
}
